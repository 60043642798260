import {TableButtonOptions} from "../../ListPage/Components/Table/Buttons/TableButtonGroup";
import {formatCnpjCpf} from "../../../Common/Utils/Format";
import {CheckboxColumnFilter} from "../../ListPage/Components/Table/Filter/DefaultColumnFilter";
import {Link} from "react-router-dom";
import React from "react";

const EmployerListSchema = () => {

  const endpoint = `${process.env.REACT_APP_ESOCIAL_SERVICE}v1/employers`

  return {
    getTitle: () => 'Lista de Empregadores',
    getEndpoint: () => endpoint,
    getHeader: () => {
      return {
        customButtons: <Link to="../empregaores-importar/form" className="btn btn-secondary btn-sm" title="Importar empregadores">
          <i className="fas fa-upload" aria-hidden="true"></i>
          &nbsp; Importar
        </Link>
      }
    },
    getColumns: () => {
      return [{
        header: "Código Externo",
        accessorKey: "externalId"
      },
        {
          header: "Nome",
          accessorKey: "fantasyName"
        },
        {
          header: "Razão Social",
          accessorKey: "companyName"
        },
        {
          header: "Usa NFE",
          accessorKey: "enableNFE",
          customFilterElement: CheckboxColumnFilter,
          accessorFn: (row) => row.enableNFE ? 'Sim' : 'Não'
        },
        {
          header: "Identificação",
          accessorFn: (row) => row.registrationType + ' - ' + formatCnpjCpf(row.registrationNumber),
          accessorKey: "registrationNumber"
        },
        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },
  };

}

export default EmployerListSchema;

