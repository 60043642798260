import BaseSelector from "../../../../Common/Component/Select/BaseSelector";

export const REGISTRATION_TYPES = [
  {id: 'CPF', label: 'CPF'},
  {id: 'CNPJ', label: 'CNPJ'}
];

const RegistrationTypeSelector = ({
  required = false,
  title = "Tipo de Pessoa",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      title={title}
      showCreateButton={false}
      required={required}
      label="label"
      setValue={setValue}
      items={REGISTRATION_TYPES}
      {...props}
  />
}

export default RegistrationTypeSelector;
