export const Footer = () => {

  return (
      <footer className="main-footer blockquote-footer">
        <strong className="mr-2">
          Copyright © {new Date().getFullYear()}
          <a className="ml-2" href="https://cflow.com.br">CFLOW</a>
        </strong>
        Todos Direitos Reservados.
        <div className="float-right d-none d-sm-inline-block">
          <b>Versão</b> {process.env.REACT_APP_BUILD_DATE || 'DEV_MODE'}
        </div>
      </footer>)
}
