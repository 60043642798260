import {Form} from "@rjsf/bootstrap-4";
import validator from '@rjsf/validator-ajv8';
import {Button, Card, Col, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import {AppButton} from "../../../Common/Component/Button/AppButton";
import {saveFormData} from "../FormDataService";
import Notify from "../../../Common/Notify/Notify";
import {getLocalDataForm, saveLocalDataForm} from "../FormDataLocalService";

export const CustomForm = ({pageSchema}) => {

  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [entityOnError, setEntityOnError] = useState(null);

  const onSubmit = (evt) => {
    let eventToSave = evt.formData;
    setLoading(true);
    saveFormData({formData: eventToSave, pageSchema}).then(x => {
      Notify.success('Entidade salva');
      navigate(-1);
      setLoading(false);
    }, err => {
      Notify.error('Falha ao salvar dados', err);
      setLoading(false);
      setEntityOnError(getLocalDataForm(pageSchema.entity));
    })
  }

  const onChange = (e, field) => {
    if (field) {
      const data = e.formData;
      saveLocalDataForm(pageSchema.entity, data);
    }
  }

  const onError = (e) => {
    console.log('on errorrrrr', e)
  }

  return <Form
      disabled={isLoading}
      schema={pageSchema.formSchema}
      validator={validator}
      showErrorList={false}
      uiSchema={pageSchema.uiSchema}
      formData={entityOnError || pageSchema.originalFormData}
      onChange={onChange}
      onSubmit={onSubmit}
      onError={onError}
      customValidate={pageSchema.customValidate}
  >
    <Card.Footer style={{margin: '-1.25rem'}}>
      <Row>
        <Col>
          {!(pageSchema.footer?.showCancelButton === false) && <Button size="sm" disabled={isLoading} variant="light" type="reset" onClick={() => navigate(-1)}>Cancelar</Button>}
        </Col>
        <Col className="text-right">
          <AppButton type="submit" isLoading={isLoading}>
            <i className="fas fa-save"></i>
            &nbsp;&nbsp;
            Salvar
          </AppButton>
        </Col>
      </Row>
    </Card.Footer>
  </Form>
}
