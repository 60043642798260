const PacienteFormSchema = () => {
  return {

    getTitle() {
      return 'Dados do Paciente'
    },
    getUrlFindById(id) {
      return `${process.env.REACT_APP_DENTAL_SERVICE}pacientes/{id}`;
    },

    getSaveUrl(id) {
      return `${process.env.REACT_APP_DENTAL_SERVICE}pacientes${id || ''}`;
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
        // "registrationNumber": {
        //   "ui:help": "CPF ou CNPJ da empresa 000.000.000-00 ou 00.000.000/0000-00",
        //   "ui:field": (props) => {
        //     return (<InputMaskedRegistrationNumber id="registrationNumber"
        //                                            title={props.schema.title} mask={props.schema.mask}
        //                                            onChangeText={props.onChange} value={props.formData}/>)
        //   },
        // },
        // "registrationType": {
        //   "ui:options": {
        //     label: false
        //   },
        //   "ui:field": (props) => {
        //     return (<RegistrationTypeSelector disabled={props.disabled} required={props.required}
        //                                       value={props.formData} setValue={props.onChange}/>
        //     );
        //   }
        // }
      }
    },

    getSchema() {
      return {
        type: "object",
        required: ["nomeCompleto"],
        properties: {
          nomeCompleto: {type: "string", title: "Nome Completo"},
          dtNascimento: {
            type: "string",
            "format": "date", title: "Data de Nascimento"
          },
          rg: {type: "string", title: "RG"},
          cpf: {type: "string", title: "CPF"},

          mae: {
            title: 'Mãe',
            type: 'object', properties: {
              nome: {type: "string", title: "Nome da Mãe"},
            }
          }
        },
      };
    }

  }
}

export default PacienteFormSchema;

