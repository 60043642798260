import {getLocalDataForm} from "./FormDataLocalService";
import {getFormData} from "./FormDataService";
import Notify from "../../Common/Notify/Notify";

const SCHEMAS = {};

export const addFormSchema = (name, schema) => {
  SCHEMAS[name] = schema;
}

export const getFormSchema = (name) => {
  return SCHEMAS[name];
}

export const mountFormObject = ({id, searchParams, entity, defaultSchema, originalFormData = {}}) => {
  return new Promise((resolve, reject) => {

    const schemaFun = defaultSchema || getFormSchema(entity)({});
    const schema = schemaFun.getSchema();
    const uiSchema = schemaFun.getUiSchema ? schemaFun.getUiSchema() : {};
    const getSaveUrl = schemaFun.getSaveUrl;
    const footer = schemaFun.getFooter && schemaFun.getFooter()
    const customValidate = schemaFun.customValidate;
    const formatObjectToSave = schemaFun.formatObjectToSave;
    const fieldToSaveName = searchParams?.get('fieldName');
    const canUseLocalData = searchParams?.has('useLocalData');

    const stateObject = {
      formSchema: schema, uiSchema, fieldToSaveName, getSaveUrl, originalFormData,
      isLoading: false, entity: entity, formatObjectToSave, dataId: id, footer,
      customValidate
    }

    if (canUseLocalData) {
      stateObject.originalFormData = getLocalDataForm(entity, {});
      const fieldToReplace = searchParams.get('useLocalData');
      stateObject.originalFormData[fieldToReplace] = getLocalDataForm(fieldToReplace, null);
      resolve(stateObject);
    }

    if (id) {
      getFormData({id, formSchema: schemaFun}).then(x => {
        stateObject.originalFormData = x;
        resolve(stateObject);
      }).catch(e => {
        Notify.error('Falha ao busscar os dados!', e);
        reject(e);
      });
    } else {
      resolve(stateObject);
    }

  });
}
