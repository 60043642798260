import {MenuItem, MenuService} from "../Common/Component/Menu/MenuService";
import {ESocialModules} from "./ESocial/ESocial.routes";
import {AuthModule} from "./Auth/AuthModule";
import {getFormSchema} from "./FormPage/FormSchemaService";
import {CrudPage} from "./CrudPage/CrudPage";
import {getListSchema} from "./ListPage/ListSchemaService";
import {NfeModuleModule} from "./NFE/NfeModule.routes";
import {DentalModule} from "./Dental/Dental.Module";

const CADASTRO_FORM = MenuItem({
  name: 'Cadastros Forms',
  path: ':operation',
  canShowInMenu: false,
  element: <CrudPage/>,
  getTitle: (params) => {
    const listSchema = getFormSchema(params.entity);
    if (listSchema) {
      const schema = listSchema();
      if (schema.getTitle) {
        return schema.getTitle(params)
      }
    }
    return null;
  }
});

const CADASTRO_FORM_EDIT = MenuItem({
  name: 'Cadastros Forms Edit',
  path: ':operation/:id',
  canShowInMenu: false,
  element: <CrudPage/>,
  getTitle: (params) => {
    const listSchema = getFormSchema(params.entity);
    if (listSchema) {
      const schema = listSchema();
      if (schema.getTitle) {
        return schema.getTitle(params)
      }
    }
    return null;
  }
});

const CADASTRO_LIST = MenuItem({
  name: 'Cadastros Lista',
  path: ':entity',
  canShowInMenu: false,
  element: <CrudPage/>,
  getTitle: (params) => {
    const listSchema = getListSchema(params.entity);
    if (listSchema) {
      const schema = listSchema();
      if (schema.getTitle) {
        return schema.getTitle(params)
      }
    }
    return null;
  },
  children: [
    CADASTRO_FORM,
    CADASTRO_FORM_EDIT]
});

export const CADASTRO_MENU = MenuItem({
  path: 'cadastros',
  icon: 'fa fa-book',
  name: 'Cadastros',
  children: [
    CADASTRO_LIST,
  ]
});

export const CONFIGURATION_MENU = MenuItem({
  path: 'configuracoes',
  icon: 'fa fa-cog',
  name: 'Configurações',
  children: [
    CADASTRO_LIST,
  ]
});

export const LoadMainModules = () => {

  MenuService.addMenu(CADASTRO_MENU);
  MenuService.addMenu(CONFIGURATION_MENU);

  return [
    ...ESocialModules(),
    ...AuthModule(),
    ...NfeModuleModule(),
    ...DentalModule()
  ]
}
