import {Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {toReal} from "../../../Common/Utils/Money";
import HttpRequest from "../../../Common/Http/HttpRequest";
import {Page} from "../../../Common/Component/Page/Page";
import {Loading} from "../../../Common/Component/Loading/Loading";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const options = {
  scales: {
    y: {
      ticks: {
        callback: function (value, index, values) {
          return 'R$ ' + toReal(value) //value.toFixed(2).replace('.',',');
        },
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Mês x Valor',
    },
  },
};

const formatDataSet = (data, labels) => {

  const dataSet = {};

  data.forEach(x => {
    const empData = dataSet[x.empregador.id] = dataSet[x.empregador.id]
        ? dataSet[x.empregador.id] : {};

    empData.empregador = x.empregador;
    empData[x.mesReferencia] = x.valorTotal;

  });

  return Object.keys(dataSet).map((empregadorId, index) => {
    const dados = dataSet[empregadorId];
    const color = intToRGB(parseInt(dados.empregador.registrationNumber));
    return {
      label: dados.empregador.fantasyName,
      data: labels.map(x => dados[x] ? dados[x] : 0),
      borderColor: 'rgb(' + color.blue + ', ' + color.green + ', ' + color.red
          + ')',
      backgroundColor: 'rgb(' + color.blue + ', ' + color.green + ', '
          + color.red + ', 0.5)',
    }
  })

}

const intToRGB = function (value) {
  //credit to https://stackoverflow.com/a/2262117/2737978 for the idea of how to implement
  var blue = Math.floor(value % 256);
  var green = Math.floor(value / 256 % 256);
  var red = Math.floor(value / 256 / 256 % 256);

  return {blue, green, red};
}

export const NfeDashboardPage = () => {

  const [dashData, setDashData] = useState(null);

  const loadLabels = (data) => {
    const labels = data.map(x => x.mesReferencia.trim().toLowerCase());

    return [...new Set(labels)];
  }




  useEffect(() => {

    const formatDashData = (data) => {
      const dataDash = {};
      dataDash.labels = loadLabels(data);
      dataDash.datasets = formatDataSet(data, dataDash.labels);
      setDashData(dataDash);
    }

    const startDate = new Date();
    startDate.setDate(-180);
    const url = `${process.env.REACT_APP_NFE_SERVICE}v1/nfe-employer/faturamento-mensal?startDate=${startDate.toISOString()}&endDate=${new Date().toISOString()}`;
    HttpRequest.get(url).then(x => {
      formatDashData(x)
    });

  }, [])

  return <Page>
    <Card>
      <Card.Body>
        <Row>
          <Col>
            {dashData ? <Line options={options} data={dashData}/> : <Loading/>}
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Page>
}
