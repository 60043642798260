import UserProfileCustomSelect from "../../Common/Component/Select/UserProfileCustomSelector";

const UserPerfilFormSchema = () => {
  return {

    getTitle() {
      return 'Permissões de usuário';
    },
    getUrlFindById(id) {
      return `auth/v1/users/${id}`;
    },

    getSaveUrl(id) {
      return `auth/v1/users/${id}/profiles`;
    },

    getSchema() {
      return {
        title: "Empresa",
        type: "array",
        required: ["profiles"],
        items: {
          type: "string"
        },
      };
    },

    getUiSchema: () => {
      return {
        // "profiles": {
        "ui:options": {
          label: false
        },
        "ui:field": (props) => {
          return (<UserProfileCustomSelect disabled={props.disabled} onlyId={true} required={props.required}
                                           value={props.formData} setValue={props.onChange}/>
          );
          // }
        }
      }
    },

  }
}

export default UserPerfilFormSchema;
