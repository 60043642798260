import {TableButtonOptions} from "../../ListPage/Components/Table/Buttons/TableButtonGroup";
import {dayMonthYearAtTime} from "../../../Common/Utils/DateFormat";
import {BlockCompanyTableButton} from "./Component/BlockCompanyTableButton";
import {formatCnpjCpf} from "../../../Common/Utils/Format";

export const CompanyListSchema = () => {
  const endpoint = `${process.env.REACT_APP_AUTH_SERVICE}v1/companies`;
  return {
    getTitle: () => {
      return 'Lista de Empresas'
    },

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "name"
        },

        {
          header: "CPF/CNPJ",
          accessorKey: "registrationNumber",
          accessorFn: (row) => formatCnpjCpf(row.registrationNumber)
        },

        {
          header: "Criado em",
          accessorKey: "createdAt",
          filter: false,
          cell: (row) => dayMonthYearAtTime(row.createdAt)
        },

        TableButtonOptions({
          deleteEndpoint: endpoint,
          children: ({row, table, cell}) => {
            return <BlockCompanyTableButton row={row} updateTable={() => {
              table.setGlobalFilter(new Date());
              console.log('atualiza tabela ai')
            }}/>
          }
        }),
      ]
    },

  }
}


