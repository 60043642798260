import {useMatches} from "react-router-dom";
import {useEffect, useState} from "react";

export const CurrentTitle = () => {

  const matches = useMatches();

  const [title, setTitle] = useState('Página');

  useEffect(() => {

    const pos = matches.length - 1;
    const match = matches[pos];
    const currentPath = match?.handle?.getName(match);
    setTitle(currentPath || 'Inicio');
  }, [matches])

  return title;

}
