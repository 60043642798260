import RegistrationTypeSelector, {REGISTRATION_TYPES} from "../../Auth/Company/Component/RegistrationTypeSelector";
import {InputMaskedRegistrationNumber} from "../../../Common/Component/Input/InputMaskedRegistrationNumber";
import CertificateSelector from "../Certificate/component/CertificateSelector";

const EmployerFormSchema = () => {
  return {

    getTitle: () => 'Dados do Empregador',

    getUrlFindById(id) {
      return `cflow-esocial/v1/employers/${id}`;
    },

    getSaveUrl(id) {
      return `cflow-esocial/v1/employers/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.registrationType = formData.registrationType.id;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.registrationType = REGISTRATION_TYPES.filter(x => x.id === formData.registrationType)[0];
      return formData;
    },

    getUiSchema: () => {
      return {
        "companyName": {
          "ui:autofocus": true,
        },
        "externalId": {
          "ui:help": "Código externo que representa este empregador no sistema integrado!"
        },
        "registrationNumber": {
          "ui:help": "CPF ou CNPJ do empregador 000.000.000-00 ou 00.000.000/0000-00",
          "ui:field": (props) => {
            return (<InputMaskedRegistrationNumber id="registrationNumber" title={props.schema.title} mask={props.schema.mask} onChangeText={props.onChange} value={props.formData}/>)
          },
        },
        "registrationType": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<RegistrationTypeSelector disabled={props.disabled} required={props.required}
                                              value={props.formData} setValue={props.onChange}/>
            );
          }
        },
        "certificate": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<CertificateSelector disabled={props.disabled}
                                         required={props.required}
                                         name={props.name}
                                         title={"Certificado ESocial"}
                                         value={props.formData}
                                         setValue={props.onChange}/>
            );
          },

        },
        "certificateNfesc": {
          "ui:field": (props) => {
            return (<CertificateSelector disabled={props.disabled}
                                         title={"Certificado Nfe"}
                                         name={props.name}
                                         required={props.required}
                                         value={props.formData}
                                         setValue={props.onChange}/>)
          }
        }
      };
    },

    getSchema() {
      return {
        type: "object",
        required: ["companyName", "registrationNumber", "externalId", "registrationType"],
        properties: {
          companyName: {type: "string", title: "Nome Fantasia"},
          fantasyName: {type: "string", title: "Razão Social"},
          externalId: {type: "string", title: "Código Externo"},
          enableNFE: {type: "boolean", title: "Usa NFE (Sistemas de Notas)"},
          certificate: {
            type: "object", title: "Certificado ESocial",
            required: ["id"],
            properties: {
              id: {
                type: 'string',
              }
            }
          },
          certificateNfesc: {
            type: "object", title: "Certificado Nfe",
            required: ["id"],
            properties: {
              id: {
                type: 'string',
              }
            }
          },
          registrationType: {
            type: "object",
            title: "Tipo de Pessoa",
            required: ["id"],
            properties: {
              id: {
                type: 'string',
                "default": REGISTRATION_TYPES[1].id,
              },
              label: {
                type: 'string',
                "default": REGISTRATION_TYPES[1].label,
              }
            }
          },
          // registrationNumber: {type: "string", title: "Identificação(CPF/CNPJ)"},
        },
        "dependencies": {
          "registrationType": {
            "oneOf": [
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_TYPES[0].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "0",
                    title: 'CPF'
                  }
                },
              },

              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_TYPES[1].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "1",
                    title: "CNPJ"
                  }
                },
              }

            ]
          }
        }
      };
    }

  }
}

export default EmployerFormSchema;

