import BaseSelector from "./BaseSelector";

export const MONTH_TYPES = [
  {label: 'Janeiro', id: 0},
  {label: 'Feveiro', id: 1},
  {label: 'Março', id: 2},
  {label: 'Abril', id: 3},
  {label: 'Maio', id: 4},
  {label: 'Junho', id: 5},
  {label: 'Julho', id: 6},
  {label: 'Agosto', id: 7},
  {label: 'Setembro', id: 8},
  {label: 'Outubro', id: 9},
  {label: 'Novembro', id: 10},
  {label: 'Dezembro', id: 11},
]

const MonthSelector = ({
  required = false,
  title = "Mês",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      title={title}
      required={required}
      label="label"
      setValue={setValue}
      items={MONTH_TYPES}
      showCreateButton={false}
      {...props}
  />
}

export default MonthSelector;
