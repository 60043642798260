import {useState} from "react";
import {LoadingButton} from "../Loading/LoadingButton";

export const RemoveButton = ({
  onClick,
  text = 'Remover',
  title = 'Remover linha',
}) => {

  const [isLoading, setIsLoading] = useState(false);

  const _onClick = async (e) => {
    setIsLoading(true);
    onClick && await onClick(e);
  }

  return (<LoadingButton size="sm" onClick={_onClick} isLoading={isLoading}
                         title={title} variantButton="danger" block={true}
                         text={text}/>)
}
