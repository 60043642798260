import {Col, Container, Row} from "react-bootstrap";
import {Breadcrumbs} from "../Breadcrumbs/Breadcrumbs";

export const Page = ({children}) => {

  return (
      <>
        <div className="content-header">
          <div className="container-fluid">
            <Row>
              <Col sm={12}>
                <Breadcrumbs/>
              </Col>
            </Row>
          </div>
        </div>

        <div className="content" style={{marginBottom: 5}}>
          <Container fluid={true}>
            {children}
          </Container>
        </div>

      </>
  )
}
