import {Spinner} from "react-bootstrap";

export const Loading = ({isLoading, children}) => {

  if (isLoading) {
    return <Spinner
        className="ml-2"
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
    />
  }

  return children;
}
