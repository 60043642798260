import HttpRequest from "../../Http/HttpRequest";

export const loadSelectData = ({filterValue, items, filterItems, label, getUrl}) => {

  if (items && items.length > 0) {
    const itemsFiltered = items
    .filter(x =>
        x[label].toLowerCase().includes(filterValue.toLowerCase())
    );
    return new Promise(r => r(itemsFiltered));
  }

  return loadServerData({filterValue, filterItems, getUrl})
}

const loadServerData = ({filterValue, filterItems, getUrl}) => {
  const url = getUrl(filterValue);
  return HttpRequest.get(url).then(x => {
    const data = x.content;
    if (filterItems) {
      return data.filter(filterItems);
    }
    return data;
  })
}
