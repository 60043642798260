import {Form} from "react-bootstrap";

export const BaseCheckboxInput = ({
  label, required, minLength, labelRequired = true, defaultValue, disabled, id, placeholder, onChange, onChangeText,
  type = 'text', showFormComponents = true, ...otherProps
}) => {

  const _onChange = (e) => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.target.checked);
  }

  return (<Form.Group className="mb-3">
    {labelRequired && <Form.Label htmlFor={id}>&nbsp;</Form.Label>}
    <Form.Check
        size="sm"
        label={label}
        required={required} defaultValue={defaultValue} disabled={disabled}
       id={id} placeholder={placeholder} onChange={_onChange}
        {...otherProps}
    />
  </Form.Group>)
}
