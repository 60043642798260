import {createContext, useEffect, useState} from "react";
import HttpRequest from "../Http/HttpRequest";
import {Loading} from "../Component/Loading/Loading";
import Notify from "../Notify/Notify";

export const UserContext = createContext(null);

export const UserLoad = ({children}) => {

  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {

    HttpRequest.get(process.env.REACT_APP_AUTH_SERVICE + 'v1/users/me').then((x, e) => {
      setCurrentUser({currentUser: x});
    }).catch(() => {
      Notify.warning('Não autorizado')
      setTimeout(() => {
        window.location = '/accounts';
      }, 500)
    });

  }, []);

  return <UserContext.Provider value={currentUser}>
    <Loading isLoading={!currentUser}>
      {currentUser && children}
    </Loading>
  </UserContext.Provider>
}

