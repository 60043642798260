import React from "react";
import {Link} from "react-router-dom";
import DropdownItem from "react-bootstrap/DropdownItem";

export const TableButton = ({onClick, icon, className, children, href}) => {

  return (
      <Link to={href} onClick={onClick} className={className}>
        <DropdownItem className={className} href={href}>
          {icon && <i className={icon}>  &nbsp;</i>}
          {children}
        </DropdownItem>
      </Link>
  )
}
