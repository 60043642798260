import React from "react";
import {Loading} from "../../../../Common/Component/Loading/Loading";

export const TableLoading = ({isLoading, children}) => {

  if (isLoading) {
    return <tbody>
    <tr>
      <td>
        <Loading isLoading={true}/>
      </td>
    </tr>
    </tbody>
  }

  return children;

}
