import {Form} from "react-bootstrap";

export const BaseTextInput = ({
  label, required, minLength, labelRequired = true, defaultValue, disabled, id, placeholder, onChange, onChangeText,
  type = 'text', showFormComponents = true, ...otherProps
}) => {

  const _onChange = (e) => {
    onChange && onChange(e);
    onChangeText && onChangeText(e.target.value);
  }

  return (<Form.Group className="mb-3">
    {labelRequired && <Form.Label htmlFor={id}>{label}</Form.Label>}
    <Form.Control
        size="sm"
        type={type} minLength={minLength} required={required} defaultValue={defaultValue} disabled={disabled}
        className="form-control" id={id} placeholder={placeholder} onChange={_onChange}
        {...otherProps}
    />
  </Form.Group>)
}
