import React from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";

export const TableColumnToggle = ({table}) => {

  return <DropdownButton className="ml-auto" variant="" title={<i className="fa fa-wrench" aria-hidden="true"></i>} style={{marginTop: '1.5rem'}}>

    <Dropdown.Item disabled>Esconder/Mostrar colunas</Dropdown.Item>

    <div className="form-check dropdown-item">
      <input className="form-check-label"
             {...{
               type: 'checkbox',
               checked: table.getIsAllColumnsVisible(),
               onChange: table.getToggleAllColumnsVisibilityHandler(),
             }}
      />{' '}
      Todas
    </div>
    <Dropdown.Divider/>

    {table.getAllLeafColumns().filter(x=> x.columnDef.header.length > 1).map(column => {
      return (
          <div key={column.id} className="form-check dropdown-item">
            <input className="form-check-label"
                   {...{
                     type: 'checkbox',
                     checked: column.getIsVisible(),
                     onChange: column.getToggleVisibilityHandler(),
                   }}
            />{' '}
            {column.columnDef.header}
          </div>
      )
    })}

  </DropdownButton>
}
