import {Button} from "react-bootstrap";
import {Loading} from "./Loading";

export const LoadingButton = ({
  isLoading, text, title,
  variantButton = 'primary',
  variantLoading = 'secondary',
  onClick = null,
  type = "submit",
  size = 'lg'
}) => {
  return (<Button onClick={onClick} title={title} disabled={isLoading} block size={size}
                  variant={variantButton}
                  type={type}>
    <Loading variant={variantLoading} isLoading={isLoading}/>
    {isLoading ? <span className="visually-hidden">Loading...</span> : text}
  </Button>);
}
