import {useEffect, useState} from "react";
import {md5} from "pure-md5";

export const Gravatar = ({email, alt}) => {

  const [gravatarHash, setHash] = useState('');

  useEffect(() => {
    if (email) {
      setHash('https://www.gravatar.com/avatar/' + md5(email) + '?s=200');
    } else {
      setHash('');
    }
  }, [email]);

  return (<img src={gravatarHash} alt={alt}/>)
}
