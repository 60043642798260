import React, {useEffect, useState} from 'react'
import {useParams} from "react-router-dom";
import HttpRequest from "../../Http/HttpRequest";
import CustomSelect from "./CustomSelect";
import Notify from "../../Notify/Notify";

export default function UserProfileCustomSelect({setValue, onlyId}) {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  let {id} = useParams();

  useEffect(() => {

    async function getAllUsers() {
      try {
        setIsLoading(true);
        const allProfiles = (await HttpRequest.get(`auth/v1/user-company-access/${id}/profiles?size=999999`)).content
        const userProfiles = (await HttpRequest.get(`auth/v1/users/${id}`)).profiles;
        setUserData({allProfiles,userProfiles})
      } catch (error) {
        Notify.error('Erro ao buscar usuários', error)
      } finally {
        setIsLoading(false);
      }
    }

    getAllUsers()
  }, [id]);

  const _setValue = (e) => {
    const data = onlyId ? e.map(x => x.id) : e;
    setValue && setValue(data);
  }

  return (<CustomSelect setValue={_setValue} isLoading={isLoading} availables={userData.allProfiles} selecteds={userData.userProfiles}/>)
}
