import React, {useCallback} from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";
import AsyncSelect from 'react-select/async';
import {useLocation, useNavigate} from "react-router-dom";
import {loadSelectData} from "./SelectData";
import Notify from "../../Notify/Notify";

const BaseSelector = ({
  required = false,
  name,
  items = [],
  label = null,
  title = '',
  filterItems = null,
  rowId = 'id',
  getUrl = null,
  setValue = null,
  setValueId,
  value = null,
  disabled = false,
  isMulti = false,
  showCreateButton = true,
  pathToCreate = '',
  showTitle = true,
  rowStyle = {},
  sm,
  placeholder = "-- selecione ou digite para buscar --"
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getOptionLabel = useCallback((row) => row[label], [label])
  const getOptionValue = useCallback((row) => row[rowId], [rowId])

  const fetchData = (filterValue, callback) => {

    loadSelectData({filterValue, filterItems, getUrl, label, items}).then(x => {
      callback(x);
    }).catch(e => Notify.error('Falha ao carregar itens', e));
  }

  const _onChange = (value) => {
    setValueId && setValueId(value?.id);
    setValue && setValue(value);
  };

  const onClickToAdd = (e) => {
    e.preventDefault();
    navigate(location.pathname + `?useLocalData=${name}`, {replace: true})
    navigate(`${pathToCreate}?fieldName=${name}`);
    return false;
  }
  const onClickToEdit = (e) => {
    e.preventDefault();
    navigate(`${pathToCreate}/${getOptionValue(value)}?fieldName=${name}`);
    return false;
  }

  const noOp = () => {
  }

  return (<Row className="align-items-center" style={{...rowStyle}}>
        <Col md={11} sm={sm || 10} xs={10} onClick={e => e.stopPropagation()}>
          <Form.Group>
            {showTitle && <Form.Label>{title}{required ? '*' : ''}</Form.Label>}
            <AsyncSelect
                isClearable
                isDisabled={disabled}
                required={required}
                cacheOptions
                defaultOptions
                value={value && Object.keys(value).length > 0 ? value : null}
                isMulti={isMulti}
                loadOptions={fetchData}
                onChange={_onChange}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                placeholder={placeholder}
            />
            {!disabled && (
                <input
                    onClick={e => e.stopPropagation()}
                    tabIndex={-1}
                    autoComplete="off"
                    style={{opacity: 0, height: 0}}
                    onChange={noOp}
                    value={value && Object.keys(value).length > 0 ? value : ''}
                    required={required}
                />
            )}
          </Form.Group>
        </Col>
        {showCreateButton && <Col md={1} sm={2} xs={2} className="text-center pb-2">

          <Button title="Criar um novo item" size="sm" onClick={onClickToAdd} style={{marginRight: 10}}>
            <i className="fas fa-plus"> </i>
          </Button>

          <Button title="Criar um novo item" size="sm" onClick={onClickToEdit} disabled={!value}>
            <i className="fas fa-pencil-alt" aria-hidden="true"></i>
          </Button>
        </Col>
        }

      </Row>
  );
};

export default BaseSelector;
