import {ListPage} from "../ListPage/ListPage";
import {useParams} from "react-router-dom";
import {FormPage} from "../FormPage/FormPage";

export const CrudPage = () => {

  const { operation} = useParams();

  if (operation === 'form') {
    return <FormPage/>
  }

  return <ListPage/>

}
