import {useState} from "react";
import Notify from "../../../../Common/Notify/Notify";
import Objects from "../../../../Common/Utils/Objects";
import FileUploadWithProgress from "../../Certificate/component/FileUploadWithProgress";

const EmployerUpload = ({
  setValue,
  ...props
}) => {

  const [employers, setEmployer] = useState([]);

  const getSignedUrl = async (event) => {
    try {
      const reader = new FileReader();
      reader.onload = ((reader) => {

        return () => {
          const contents = reader.result;
          const lines = contents.split('\n')
          .filter(x => !Objects.isEmptyOrBlank(x));
          const employers = lines.map(line => {
            const columns = line.split(',');

            if (columns.length < 3) {
              Notify.warning(`Falha ao importar linha: ${line}`);
              return null;
            }

            const externalId = columns[0];
            const fantasyName = columns[1];
            const registrationNumber = columns[2].replace('\r', '');

            if (Objects.isEmptyOrBlank(externalId) || Objects.isEmptyOrBlank(fantasyName)
                || Objects.isEmptyOrBlank(registrationNumber)) {
              Notify.warning(`Dados inválidos na linha: ${line}`);
              return null;
            }

            return {
              externalId: columns[0],
              fantasyName: columns[1],
              companyName: columns[1],
              registrationNumber: columns[2].replace('\r', '')
            };
          }).filter(x => x !== null);

          setEmployer(employers);
          setValue && setValue(employers);
        }
      })(reader);

      reader.readAsText(event);
    } catch (e) {
      Notify.error('Falha ao buscar carregar arquivo', e);
    }
  }

  return <>
    <FileUploadWithProgress
        {...props}
        accept=".txt"
        getSignedUrl={getSignedUrl}
        progressBar={false}
    />
    <p>Total de Empregadores: {employers.length}</p>
  </>

}

export default EmployerUpload;
