import {TableButtonOptions} from "../../ListPage/Components/Table/Buttons/TableButtonGroup";
import {dayMonthYearAtTime} from "../../../Common/Utils/DateFormat";

const IntegrationUserListSchema = () => {
  const endpoint = `${process.env.REACT_APP_AUTH_SERVICE}v1/integration-users`;
  return {
    getTitle: () => 'Usuários de Integração',

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "name"
        },
        {
          header: "Chave de Acesso",
          accessorKey: "token"
        },
        {
          header: "Criado em",
          accessorKey: "createdAt",
          enableColumnFilter: false,
          accessorFn: (row) => dayMonthYearAtTime(row.createdDate) || console.log('row', row)
        },
        TableButtonOptions({
          deleteEndpoint: endpoint
        })
      ]
    },

  }
}

export default IntegrationUserListSchema;

