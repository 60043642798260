import React, {useContext} from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {BaseLayout} from "../Layout/BaseLayout";
import {MapMenusToRoute} from "../Menu/MenuService";
import {UserContext} from "../../Context/UserContext";

export const MyRoutes = () => {

  const {currentUser} = useContext(UserContext);

  const router = createBrowserRouter([{
    path: "/",
    element: <BaseLayout/>,
    children: MapMenusToRoute(currentUser)
  }])

  return <RouterProvider router={router}/>
};
