import {useContext} from "react";
import {UserContext} from "../../Context/UserContext";
import {SidebarMenuItem} from "./SidebarMenuItem";
import {MenuService} from "../Menu/MenuService";

export const SidebarItem = () => {

  const {currentUser} = useContext(UserContext);
  const menus = MenuService.getMenus(currentUser);
  return menus.map(x => <SidebarMenuItem key={x.path} menu={x} currentUser={currentUser}/>);
}
