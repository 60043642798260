import {TableButtonOptions} from "../ListPage/Components/Table/Buttons/TableButtonGroup";
import {dayMonthYear, dayMonthYearAtTime} from "../../Common/Utils/DateFormat";
import {toReal} from "../../Common/Utils/Money";
import {TableButton} from "../ListPage/Components/Table/Buttons/TableButton";
import {DateRangeColumnFilter} from "../ListPage/Components/Table/Filter/DefaultColumnFilter";

export const NfeListSchema = () => {

  const endpoint = 'cflow-sefaz/v1/nfe-sc';

  const downloadEvent = (nfeXML, nfeID) => {
    const filename = `${nfeID}.xml`;
    const dataText = 'data:application/xml;charset=utf-8,' + nfeXML;
    const url = encodeURI(dataText);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.click();
  }

  return {
    title: 'Notas Fiscais Eletrônicas',

    getEndpoint: () => endpoint,

    getHeader: () => {
      return {
        showNewButton: false,
      };
    },

    getColumns: () => {
      return [
        {
          header: 'Série',
          accessorKey: "serie",
          minWidth: 50,
          width: 50,
          // Cell: (row) => row.sserie,
        }, {
          header: 'Nº Documento',
          accessorKey: "numeroNfe",
          minWidth: 80,
          width: 80,
          // Filter: DefaultColumnFilter,
          // cell: (row) => row.original.numeroNfe,
        },
        {
          header: 'Empresa emitente',
          accessorKey: "emitente.companyName",
          accessorFn: (row) => row.emitente?.companyName,
        },
        {
          header: 'Empresa destino',
          accessorKey: "destinatario.companyName",
          accessorFn: (row) => row.destinatario?.companyName,
        },
        {
          header: 'Data',
          accessorKey: "dtEmissao",
          customFilterElement: DateRangeColumnFilter,
          accessorFn: (row) => row.dtEmissao && dayMonthYearAtTime(row.dtEmissao)
        },
        {
          header: 'Valor total',
          accessorKey: "valorTotal",
          accessorFn: (row) => row.valorTotal && toReal(row.valorTotal)
        },
        TableButtonOptions({
          deleteEndpoint: endpoint,
          showEditButton: false,
          showDeleteButton: false,
          children: ({row}) => <TableButton title="Download da NFE" icon="fas fa-download" className="bg-primary" onClick={() => downloadEvent(row.original?.nfeXML, row.original?.nfeId)}>BAIXAR NFE</TableButton>

        })
        // OptionColumn({
        //   showEdit: false,
        //   showDelete: false,
        //   children: ({row}) => (
        //       <Row>
        //         <TableButton title="Download da NFE" icon="fas fa-download" variant="primary" onClick={() => downloadEvent(row.original?.nfeXML, row.original?.nfeId)}>BAIXAR NFE</TableButton>
        //       </Row>
        //   )
        // }),
      ];
    },
  };
};

