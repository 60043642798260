import React from "react";
import {TableButton} from "./TableButton";
import HttpRequest from "../../../../../Common/Http/HttpRequest";
import Notify from "../../../../../Common/Notify/Notify";

export const DeleteButton = ({row, updateTable, deleteEndpoint}) => {

  const doDelete = () => {
    const url = `${deleteEndpoint}/${row.original.id}`
    HttpRequest.delete(url).then(x => {
      updateTable();
      Notify.success('Entidade removida!');
    }).catch(e => {
      Notify.error('Falha ao remover entidade', e);
    })
  }

  return (<TableButton className="btn-danger bg-danger" onClick={doDelete}>
        <span className="text-left">
          <i className="fa fa-trash" aria-hidden="true"></i>
        </span>
        &nbsp; Deletar
      </TableButton>
  )
}
