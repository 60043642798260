import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

export const ListPageHeader = ({headerConfig}) => {

  const {showNewButton = true, linkNewUrl = 'form', customButtons} = headerConfig;

  return (
      <Row>
        <Col className="mr-1 mb-3">
          {showNewButton && <Link to={linkNewUrl} className="btn btn-primary btn-sm mr-2" title="Criar um novo registro">
            <i className="fa fa-plus" aria-hidden="true"></i>
            &nbsp; Novo
          </Link>
          }
          {customButtons && customButtons}
        </Col>



      </Row>)

}

