import BaseSelector from "../../../Common/Component/Select/BaseSelector";

const EmployerSelector = ({
  required = false,
  defaultValue,
  title = "Empregador",
  setValue = null,
  isEnableNfe = false,
  ...props
}) => {

  const getUrl = (filterValue) => {
    const enableNfe = isEnableNfe ? '&enableNFE=true' : '';
    return `cflow-esocial/v1/employers?sort=name,ASC&fantasyName=${filterValue}${enableNfe}`;
  }

  return <BaseSelector
      defaultValue={defaultValue}
      title={title}
      required={required}
      label="fantasyName"
      showCreateButton={false}
      getUrl={getUrl}
      setValue={setValue}
      {...props}
  />

}

export default EmployerSelector;
