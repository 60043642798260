import {Page} from "../../../Common/Component/Page/Page";
import {Col, Row} from "react-bootstrap";
import {useContext} from "react";
import {UserContext} from "../../../Common/Context/UserContext";
import UserDetailsForm from "./Component/UserDetailsForm";
import UserDetailsPasswordForm from "./Component/UserDetailsPasswordForm";

const CurrentUserPage = () => {

  const {currentUser} = useContext(UserContext);

  return (<Page>
        <Row>
          <Col sm={12} md={6}>
            <UserDetailsForm currentUser={currentUser}/>
          </Col>

          <Col sm={12} md={6}>
            <UserDetailsPasswordForm/>
          </Col>
        </Row>
      </Page>
  )

};
export default CurrentUserPage;
