import {Card, Col, Row} from "react-bootstrap";
import {ReactTable} from "../../../ListPage/Components/Table/ReactTable";
import moment from "moment";
import {dayMonthYearAtTime} from "../../../../Common/Utils/DateFormat";
import {toReal} from "../../../../Common/Utils/Money";

const getDate = () => {
  const now = new Date();
  return moment(now).subtract(10, 'minutes').toISOString();
}

const tableSchema = ({employer}) => {
  return {
    getEndpoint: () => 'cflow-sefaz/v1/nfe-sc/employers/' + employer.id + '/imported-nfescs?filterDate=' + getDate() + '&',
    getColumns: () => {
      return [
        {
          header: 'Empresa emitente',
          accessorKey: "emitente.companyName",
          filter: false,
          cell: (row) => row.emitente.companyName,
        },
        {
          header: 'Empresa destino',
          accessorKey: "destinatario.companyName",
          filter: false,
          cell: (row) => row.destinatario.companyName,
        },
        {
          header: 'Data de Emissão',
          accessorKey: "dtEmissao",
          filter: false,
          // Filter: DateTimeRangeColumnFilter,
          cell: (row) => row.dtEmissao && dayMonthYearAtTime(row.original.dtEmissao)
        },
        {
          header: 'Valor total',
          accessorKey: "valorTotal",
          filter: false,
          cell: (row) => row.valorTotal && toReal(row.original.valorTotal)
        },
      ];
    }
  };
}

export const ImportedNfeList = ({employer}) => {

  const schema = tableSchema({employer});

  return <Card> <Card.Body>
    <Row>
      <Col sm={12}>
        <ReactTable tableSchema={schema}/>
      </Col>
    </Row>
  </Card.Body>
  </Card>
}
