import {MenuItem, MenuService} from "../../Common/Component/Menu/MenuService";
import {NfeImportPage} from "./NfeImport/NfeImportPage";
import {NfeListSchema} from "./NfeListSchema";
import {addListSchema, getListSchema} from "../ListPage/ListSchemaService";
import {CrudPage} from "../CrudPage/CrudPage";
import {DownloadNfePage} from "./DownloadNFE/DownloadNfePage";
import {NfeDashboardPage} from "./Dashboard/NfeDashboardPage";
import {DownloadSpedFiscalPage} from "./DownloadSped/DownloadSpedFiscalPage";
import {Roles} from "../Auth/Roles";

export const NfeModuleModule = () => {

  const NFE_MANUAL_IMPORT_HOME = MenuItem({
    name: 'Importar NFEs Manual', path: 'importador-manual', icon: 'fa fa-file-import', element: <NfeImportPage/>
  });

  const NFE_BASE_LIST = MenuItem({
    name: 'NFE Lista',
    path: ':entity',
    canShowInMenu: false,
    element: <CrudPage/>,
    getTitle: (params) => {
      const listSchema = getListSchema(params.entity);
      if (listSchema) {
        const schema = listSchema();
        if (schema.getTitle) {
          return schema.getTitle(params)
        }
      }
      return null;
    },
    children: []
  });

  const NFE_LIST = MenuItem({
    name: 'Notas', path: 'notas', icon: 'fa fa-book-open', canCreateRoute: false
  });

  const NFE_DOWNLOAD_PAGE = MenuItem({
    name: 'Baixar Notas Mensais', path: 'baixar-notas', icon: 'fa fa-file-download', element: <DownloadNfePage/>
  });

  const SPED_DOWNLOAD_PAGE = MenuItem({
    name: 'Baixar SPED Fiscal Mensal', path: 'baixar-sped-fiscal', icon: 'fa fa-file-download', element: <DownloadSpedFiscalPage/>, roles: [Roles.ROLE_SUPER, Roles.ROLE_SPED]
  });


  const NFE_DASHBOARD_PAGE = MenuItem({
    name: 'Faturamento Mensal', path: 'faturamento-mensal', icon: 'fa fa-briefcase', element: <NfeDashboardPage/>
  });

  const NFE_HOME = MenuItem({
    name: 'Notas Fiscais', path: 'nfesc', icon: 'fa fa-cogs',
    children: [NFE_MANUAL_IMPORT_HOME, NFE_BASE_LIST, NFE_LIST, NFE_DOWNLOAD_PAGE, NFE_DASHBOARD_PAGE, SPED_DOWNLOAD_PAGE]
  });

  addListSchema('notas', NfeListSchema);

  MenuService.addMenu(NFE_HOME);

  return []
}
