import BaseSelector from "../../../../Common/Component/Select/BaseSelector";

const CertificateSelector = ({
  required = false,
  defaultValue,
  title = "Certificado",
  setValue = null,
  ...props
}) => {

  const getUrl = (filterValue) => {
    return `cflow-esocial/v1/certificates?sort=name,ASC&name=${filterValue}`;
  }

  return <BaseSelector
    defaultValue={defaultValue}
    title={title}
    required={required}
    pathToCreate='/cadastros/certificados/form'
    label="name"
    getUrl={getUrl}
    setValue={setValue}
    {...props}
  />

}

export default CertificateSelector;
