import {MenuItem, MenuService} from "../../Common/Component/Menu/MenuService";
import {CertificateListSchema} from "./Certificate/CertificateListSchema";
import {CADASTRO_MENU, CONFIGURATION_MENU} from "../Modules.routes";
import {addListSchema} from "../ListPage/ListSchemaService";
import EmployerListSchema from "./Employers/EmployerListSchema";
import {addFormSchema} from "../FormPage/FormSchemaService";
import EmployerFormSchema from "./Employers/EmployerFormSchema";
import {CertificateFormSchema} from "./Certificate/CertificateFormSchema";
import DashboardPage from "./Home/DashboardPage";
import {ESocialUserPreferenceFormSchema} from "./Configuration/ESocialUserPreferenceFormSchema";
import EmployerImportFormSchema from "./Employers/EmployerImportFormSchema";

export const ESocialModules = () => {

  const CERTIFICATE_LIST = MenuItem({
    name: 'Certificados', path: 'certificados', icon: 'fa fa-stamp', canCreateRoute: false
  });

  const EMPLOYER_LIST = MenuItem({
    name: 'Empregadores', path: 'empregadores', icon: 'fa fa-industry', canCreateRoute: false
  });

  addListSchema('empregadores', EmployerListSchema);
  addListSchema('certificados', CertificateListSchema);

  addFormSchema('empregadores', EmployerFormSchema)
  addFormSchema('certificados', CertificateFormSchema)
  addFormSchema('empregaores-importar', EmployerImportFormSchema)

  CADASTRO_MENU.children.push(CERTIFICATE_LIST);
  CADASTRO_MENU.children.push(EMPLOYER_LIST);


  addFormSchema('esocial-preferencias', ESocialUserPreferenceFormSchema)

  const USER_PREFERENCE_ESOCIAL_LIST = MenuItem({
    name: 'Configurações do ESocial', path: 'esocial-preferencias/form/1', icon: 'fa fa-industry', canCreateRoute: false
  });

  CONFIGURATION_MENU.children.push(USER_PREFERENCE_ESOCIAL_LIST)

  MenuService.addMenu(MenuItem({
    path: '/esocial', name: 'ESOCIAL', newTab: true, icon: 'fa fa-cloud', canCreateRoute: false, order: 2
  }));

  const HOME = MenuItem({
    name: 'Inicío', path: '/', icon: 'fa fa-stamp', canCreateRoute: true, canShowInMenu: false,
    element: <DashboardPage/>
  });

  MenuService.addMenu(HOME);


  return []
}
