import RegistrationTypeSelector, {REGISTRATION_TYPES} from "./Component/RegistrationTypeSelector";
import {InputMaskedRegistrationNumber} from "../../../Common/Component/Input/InputMaskedRegistrationNumber";

const CompanyFormSchema = () => {
  return {

    getTitle() {
      return 'Dados da Empresa'
    },
    getUrlFindById(id) {
      return `${process.env.REACT_APP_AUTH_SERVICE}v1/companies/${id}`;
    },

    getSaveUrl(id) {
      return `${process.env.REACT_APP_AUTH_SERVICE}v1/companies/${id || ''}`;
    },

    formatObjectToSave(formData) {
      formData.registrationType = formData.registrationType.id;
      return formData;
    },

    formatObjectToGet(formData) {
      formData.registrationType = REGISTRATION_TYPES.filter(x => x.id === formData.registrationType)[0];
      return formData;
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
        "registrationNumber": {
          "ui:help": "CPF ou CNPJ da empresa 000.000.000-00 ou 00.000.000/0000-00",
          "ui:field": (props) => {
            return (<InputMaskedRegistrationNumber id="registrationNumber"
                                                   title={props.schema.title} mask={props.schema.mask}
                                                   onChangeText={props.onChange} value={props.formData}/>)
          },
        },
        "registrationType": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<RegistrationTypeSelector disabled={props.disabled} required={props.required}
                                              value={props.formData} setValue={props.onChange}/>
            );
          }
        }
      }
    },

    getSchema() {
      return {
        // title: "Empresa",
        type: "object",
        required: ["name"],
        properties: {
          name: {type: "string", title: "Nome Fantasia"},
          registrationType: {
            type: "object",
            title: "Tipo de Pessoa",
            required: ["id"],
            properties: {
              id: {
                type: 'string',
                "default": REGISTRATION_TYPES[1].id,
              },
              label: {
                type: 'string',
                "default": REGISTRATION_TYPES[1].label,
              }
            }
          },
        },
        "dependencies": {
          "registrationType": {
            "oneOf": [
              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_TYPES[0].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    "mask": "0",
                    title: 'CPF'
                  }
                },
              },

              {
                "properties": {
                  "registrationType": {
                    "properties": {
                      "id": {
                        "enum": [
                          REGISTRATION_TYPES[1].id
                        ]
                      }
                    }
                  },
                  "registrationNumber": {
                    "type": "string",
                    minLength: 14,
                    maxLength: 14,
                    "mask": "1",
                    title: "CNPJ"
                  }
                },
              }
            ]
          }
        }
      };
    }

  }
}

export default CompanyFormSchema;

