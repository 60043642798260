import {dayMonthYear, dayMonthYearAtTime} from "../../../Common/Utils/DateFormat";
import {TableButtonOptions} from "../../ListPage/Components/Table/Buttons/TableButtonGroup";
import {DateColumnFilter} from "../../ListPage/Components/Table/Filter/DefaultColumnFilter";

export const PacienteListSchema = () => {

  const endpoint = process.env.REACT_APP_DENTAL_SERVICE + 'pacientes';

  return {
    getTitle: () => {
      return 'Lista de Pacientes'
    },
    getEndpoint() {
      return endpoint;
    },
    getColumns: () => {
      return [
        {
          header: "Nome Completo",
          accessorKey: "nomeCompleto"
        },
        {
          header: "Data de Nascimento",
          accessorKey: "dtNascimento",
          customFilterElement: DateColumnFilter,
          accessorFn: (row) => dayMonthYear(row.dtNascimento)
        },
        {
          header: "Criado em",
          accessorKey: "createdAt",
          enableColumnFilter: false,
          accessorFn: (row) => dayMonthYearAtTime(row.createdAt)
        },
        TableButtonOptions({
          deleteEndpoint: endpoint
        }),
      ]
    }

    // return {
    //   getEndpoint: () => 'cflow-esocial/v1/certificates',
    //
    //   // getHeader: () => {
    //   //   return {
    //   //     linkNewUrl: ROUTE_PATH.CERTIFICATE_FORM
    //   //   }
    //   // },
    //

    // },
  }
}
