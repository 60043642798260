import {toast} from 'react-toastify';

export default class Notify {

  static error(message, error, options = {
    404: 'Não encontrado',
    500: 'Erro interno',
    409: 'Sua tela está desatualizada'
  }) {

    console.log('notify error', error);
    let cause = error && error.message;

    if (error) {
      if (error.response) {
        if (error.response.data) {
          cause = error.response.data.message
        }
      }
    }

    const messageToShow = !cause ? message : message + ': ' + cause;
    toast.error(messageToShow, {
      position: toast.POSITION.TOP_CENTER
    });
  }

  static success(message) {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER
    });
    return true;
  }

  static warning(message) {
    toast.warn(message, {
      position: toast.POSITION.TOP_CENTER
    });
  }
}
