import {Form, ProgressBar} from "react-bootstrap";
import {useState} from "react";
import ReactS3Uploader from "react-s3-uploader";
import Notify from "../../../../Common/Notify/Notify";
import {RemoveButton} from "../../../../Common/Component/Button/RemoveButton";

const FileUploadWithProgress = ({
  label,
  getSignedUrl, accept, setValue,
  progressBar = true,
  value
}) => {

  const [percentage, setPercentage] = useState(0);

  const onError = (e) => {
    Notify.error('Falha ao enviar arquivo', e);
  }

  const onProgress = (percent, message) => {
    setPercentage(percent);
  }

  const onFinish = (a) => {
    setValue && setValue(a);
  }

  const remove = () => {
    setValue && setValue(null);
  }

  return <Form.Group>
    <Form.Label>{label}</Form.Label>
    {!value && <ReactS3Uploader
        getSignedUrl={getSignedUrl}
        accept={accept}
        onFinish={onFinish}
        onError={onError}
        onProgress={onProgress}
        uploadRequestHeaders={{}}
        contentDisposition="auto"
    />}

    {value && value?.name && <p>{value.name}</p>}

    {!value && !value?.name && progressBar && <ProgressBar label={percentage === 100 ? 'Enviado' : `Enviando ${percentage}%`} striped={true}
                                                           now={percentage}/>}

    {value && <RemoveButton onClick={remove}>Remover</RemoveButton>}

  </Form.Group>
}

export default FileUploadWithProgress;
