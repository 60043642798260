export const isNullOrUndefined = (text) => {
  return text === null || text === undefined;
};

export const getValueByStringOrFunction = (value) => {
  if (typeof (value) === 'function') {
    return value(this.props, this.state);
  }
  return value;
};

export const getValueByCustomProperty = (property, value) => {
  const properties = property.split('.');
  let currentValue = value;
  properties.forEach(prop => currentValue = currentValue[prop]);
  return currentValue;
};

export const setValueByCustomProperty = (property, object, valueToSet) => {
  const properties = property.split('.');
  let lastPath = object;
  properties.forEach((prop, index) => {
    lastPath[prop] = lastPath[prop] || {};
    index === properties.length - 1 ? lastPath[prop] = valueToSet : lastPath = lastPath[prop];
  });
  return object;
};

window.denner = setValueByCustomProperty;

export default class Objects {

  static nonEmptyOrBlank(text) {
    return !Objects.isEmptyOrBlank(text);
  }

  static isEmptyOrBlank(text) {
    if (Objects.isNullOrUndefined(text)) {
      return true;
    }

    if (text.length > 0) {
      return false;
    }

    return true;
  }

  static isNullOrUndefined = isNullOrUndefined;
}


