export const UserPasswordFormSchema = () => {

  return {

    getSaveUrl() {
      return `${process.env.REACT_APP_AUTH_SERVICE}v1/users/change-password`;
    },

    getFooter() {
      return {showCancelButton: false}
    },

    customValidate(formData, errors) {
      if (formData.newPassword !== formData.newPasswordRepeat) {
        errors.newPasswordRepeat.addError("A senha não é igual!");
      }
      return errors;
    },

    getSchema() {
      return {
        type: "object",
        required: ["currentPassword", "newPassword", "newPasswordRepeat"],
        properties: {
          currentPassword: {type: "string", title: "Senha atual"},
          newPassword: {type: "string", format: 'password', title: "Nova senha", "minLength": 6},
          newPasswordRepeat: {type: "string", format: 'password', title: "Nova senha", "minLength": 6},
        },
      };
    }

  }

}
