import {memo} from "react";
import HttpRequest from "../../../../Common/Http/HttpRequest";
import Notify from "../../../../Common/Notify/Notify";
import FileUploadWithProgress from "./FileUploadWithProgress";

const CertificateUpload = ({
  ...props
}) => {

  const getSignedUrl = async (file, callback) => {
    try {
      const data = await HttpRequest.get(`${process.env.REACT_APP_ESOCIAL_SERVICE}v1/certificates/upload`);
      callback({
        signedUrl: data.url,
        fullPath: data.fullPath,
        name: data.name
      });
    } catch (e) {
      Notify.error('Falha ao buscar url para upload', e);
    }
  }

  return <FileUploadWithProgress
      {...props}
      getSignedUrl={getSignedUrl}
  />

}

export default memo(CertificateUpload);
