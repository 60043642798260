import {dayMonthYear, dayMonthYearAtTime} from "../../../Common/Utils/DateFormat";
import {TableButtonOptions} from "../../ListPage/Components/Table/Buttons/TableButtonGroup";

export const CertificateListSchema = () => {

  const endpoint = process.env.REACT_APP_ESOCIAL_SERVICE + 'v1/certificates';
  return {
    getTitle: () => {
      return 'Lista de Certificados'
    },
    getEndpoint() {
      return endpoint;
    },
    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "name"
        },
        {
          header: "Expira em",
          enableColumnFilter: false,
          accessorKey: "expiryIn",
          accessorFn: (row) => dayMonthYear(row.expiryIn),
        },
        {
          header: "Criado em",
          accessorKey: "createdAt",
          enableColumnFilter: false,
          accessorFn: (row) => dayMonthYearAtTime(row.createdAt)
        },
        TableButtonOptions({
          deleteEndpoint: endpoint
        }),
      ]
    }

    // return {
    //   getEndpoint: () => 'cflow-esocial/v1/certificates',
    //
    //   // getHeader: () => {
    //   //   return {
    //   //     linkNewUrl: ROUTE_PATH.CERTIFICATE_FORM
    //   //   }
    //   // },
    //

    // },
  }
}
