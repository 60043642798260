import {TableButton} from "../../../ListPage/Components/Table/Buttons/TableButton";
import React from "react";
import Notify from "../../../../Common/Notify/Notify";
import HttpRequest from "../../../../Common/Http/HttpRequest";

export const BlockCompanyTableButton = ({updateTable, row}) => {

  const {id, active} = row.original;

  const blockCompany = () => {
    const suffixURL = active ? 'block' : 'unblock';
    HttpRequest.put(`${process.env.REACT_APP_AUTH_SERVICE}v1/companies/${id}/${suffixURL}`).then(() => {
      if (active) {
        Notify.success('Empresa bloqueada');
      } else {
        Notify.success('Empresa desbloqueada');
      }
      updateTable();
    }).catch(e => Notify.error('Falha ao bloquear empresa', e));
  }

  return (<TableButton className="bg-warning btn-warning" onClick={blockCompany} variant="warning">
        <span className="text-left">
          <i className="fas fa-lock" aria-hidden="true"></i>
        </span>
    &nbsp; {active ? 'Bloquear' : 'Desbloquear'}
  </TableButton>)
}
