import React from "react";
import {Col, Row} from "react-bootstrap";
import {DefaultColumnFilter} from "./Filter/DefaultColumnFilter";
import {flexRender} from "@tanstack/react-table";
import {TableColumnToggle} from "./Column/TableColumnToggle";

const MapFilterElement = (x) => {
  if (x.column.columnDef.customFilterElement) {
    return flexRender(x.column.columnDef.customFilterElement, x.getContext());
  }
  return <DefaultColumnFilter header={x}/>
}

const MapFilter = (x) => {
  const filterElement = MapFilterElement(x);

  return <Col key={x.id}> {filterElement}</Col>
}

const getColumnsFiltered = (headerGroup) => {
  return headerGroup.headers
  .filter(x => x.column.getCanFilter())
  .map(x => MapFilter(x));
}
export const TableFilter = ({
  table,
  headerGroups
}) => {
  const filtersToRender = headerGroups.map(headerGroup => getColumnsFiltered(headerGroup));
  if (filtersToRender.length === 0) {
    return null;
  }
  return <Row>
    {filtersToRender}
    <TableColumnToggle table={table}/>
  </Row>

}

/*
{.map(headerGroup => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map(header => {
        return (
            <th key={header.id} colSpan={header.colSpan}>
              {header.isPlaceholder ? null : (
                  <>
                    <div
                        {...{
                          className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                    >
                      {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                      )}
                      {{
                        asc: ' 🔼',
                        desc: ' 🔽',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                    {header.column.getCanFilter() ? (
                        <div>
                          <Filter column={header.column} table={table} />
                        </div>
                    ) : null}
                  </>
              )}
            </th>
        )
      })}
    </tr>
))}*/

// function Filter({
//   column,
//   table,
// }: {
//   column: Column<any, unknown>
//   table: Table<any>
// }) {
//   const firstValue = table
//   .getPreFilteredRowModel()
//       .flatRows[0]?.getValue(column.id)
//
//   const columnFilterValue = column.getFilterValue()
//
//   const sortedUniqueValues = React.useMemo(
//       () =>
//           typeof firstValue === 'number'
//               ? []
//               : Array.from(column.getFacetedUniqueValues().keys()).sort(),
//       [column.getFacetedUniqueValues()]
//   )
//
//   return typeof firstValue === 'number' ? (
//       <div>
//         <div className="flex space-x-2">
//           <DebouncedInput
//               type="number"
//               min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
//               max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
//               value={(columnFilterValue as [number, number])?.[0] ?? ''}
//               onChange={value =>
//                   column.setFilterValue((old: [number, number]) => [value, old?.[1]])
//               }
//               placeholder={`Min ${
//                   column.getFacetedMinMaxValues()?.[0]
//                       ? `(${column.getFacetedMinMaxValues()?.[0]})`
//                       : ''
//               }`}
//               className="w-24 border shadow rounded"
//           />
//           <DebouncedInput
//               type="number"
//               min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
//               max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
//               value={(columnFilterValue as [number, number])?.[1] ?? ''}
//               onChange={value =>
//                   column.setFilterValue((old: [number, number]) => [old?.[0], value])
//               }
//               placeholder={`Max ${
//                   column.getFacetedMinMaxValues()?.[1]
//                       ? `(${column.getFacetedMinMaxValues()?.[1]})`
//                       : ''
//               }`}
//               className="w-24 border shadow rounded"
//           />
//         </div>
//         <div className="h-1" />
//       </div>
//   ) : (
//       <>
//         <datalist id={column.id + 'list'}>
//           {sortedUniqueValues.slice(0, 5000).map((value: any) => (
//               <option value={value} key={value} />
//           ))}
//         </datalist>
//         <DebouncedInput
//             type="text"
//             value={(columnFilterValue ?? '') as string}
//             onChange={value => column.setFilterValue(value)}
//             placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
//             className="w-36 border shadow rounded"
//             list={column.id + 'list'}
//         />
//         <div className="h-1" />
//       </>
//   )
// }
