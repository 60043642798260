import {useMatches} from "react-router-dom";

export const Breadcrumbs = () => {

  let matches = useMatches();

  let crumbs = matches
  // first get rid of any matches that don't have handle and crumb
  .filter((match) => Boolean(match.handle?.crumb))
  // now map them into an array of elements, passing the loader
  // data to each one
  .map((match) => {
    return match.handle.crumb(match)
  }).filter(x => x);

  return (<ol className="breadcrumb float-sm-right">
    {crumbs.map((crumb, index) => (
        <li key={index} className="breadcrumb-item">
          {crumb}
        </li>
    ))}
  </ol>);
}
// withBreadcrumbs()(({breadcrumbs}) => {
//
//
//   return (<ol className="breadcrumb float-sm-right">
//     {breadcrumbs.map(({match, breadcrumb}) => (
//       <li key={match.url} className="breadcrumb-item">
//         <Link to={match.url}>{breadcrumb}</Link>
//       </li>
//     ))}
//   </ol>);
// });
