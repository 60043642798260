import {useEffect, useState} from "react";
import {Card} from "react-bootstrap";
import {CustomForm} from "../../../FormPage/Components/CustomForm";
import {mountFormObject} from "../../../FormPage/FormSchemaService";
import {UserPasswordFormSchema} from "./UserPasswordFormSchema";

const UserDetailsPasswordForm = () => {

  const [pageSchema, setPageSchema] = useState(null);

  useEffect(() => {
    mountFormObject({
      defaultSchema: UserPasswordFormSchema({})
    }).then(x => {
      setPageSchema(x);
    });
  }, []);

  return (
      <Card className="card-primary">
        <Card.Header>
          <Card.Title>Senha</Card.Title>
        </Card.Header>
        <Card.Body>
          {pageSchema && <CustomForm pageSchema={pageSchema}/>}
        </Card.Body>
      </Card>)

};
export default UserDetailsPasswordForm;
