import {Page} from "../../Common/Component/Page/Page";
import {Card, Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {Loading} from "../../Common/Component/Loading/Loading";
import {ReactTable} from "./Components/Table/ReactTable";
import {ListPageHeader} from "./Components/ListPageHeader";
import {useParams} from "react-router-dom";
import {getListSchema} from "./ListSchemaService";

export const ListPage = () => {

  const [pageSchema, setPageSchema] = useState(null);
  const {entity} = useParams();

  useEffect(() => {

    if (!entity) {
      return;
    }

    const schemaFunc = getListSchema(entity);
    const schema = schemaFunc({});

    const header = (schema.getHeader && schema.getHeader()) || {};

    setPageSchema({header, tableSchema: schema});
  }, [entity]);

  if (!pageSchema) {
    return <Loading/>
  }

  return (
      <Page>
        <Card>
          <Card.Body>
            <ListPageHeader headerConfig={pageSchema.header}/>
            <Row>
              <Col>
                <ReactTable tableSchema={pageSchema.tableSchema}/>
              </Col>
            </Row>

          </Card.Body>
        </Card>
      </Page>
  )
}
