const IntegrationUserFormSchema = () => {
  return {

    getTitle(isNew) {
      return isNew ? 'Novo Usuário de Integração' : 'Editar Usuário de Integração';
    },
    getUrlFindById(id) {
      return `cflow-auth/v1/integration-users/${id}`;
    },

    getSaveUrl(id) {
      return `cflow-auth/v1/integration-users/${id || ''}`;
    },

    formatObjectToSave(formData) {
      return formData;
    },

    formatObjectToGet(formData) {
      return formData;
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
      }
    },

    getSchema() {
      return {
        type: "object",
        required: ["name"],
        properties: {
          name: {type: "string", title: "Nome"},
        },
      };
    }

  }
}

export default IntegrationUserFormSchema;

