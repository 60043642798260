import {Card, Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {CustomForm} from "../../../FormPage/Components/CustomForm";
import {UserDetailFormSchema} from "./UserDetailFormSchema";
import {mountFormObject} from "../../../FormPage/FormSchemaService";

const UserDetailsForm = ({currentUser}) => {

  const [pageSchema, setPageSchema] = useState(null);

  useEffect(() => {
    mountFormObject({
      defaultSchema: UserDetailFormSchema({}),
      originalFormData: currentUser

    }).then(x => {
      setPageSchema(x);
    });
  }, [currentUser]);

  return (
      <Card className="card-primary">
        <Card.Header>
          <Card.Title>Configurações</Card.Title>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {pageSchema && <CustomForm pageSchema={pageSchema}/>}
            </Col>
          </Row>
        </Card.Body>
      </Card>)

};
export default UserDetailsForm;
