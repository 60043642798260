import {Link} from "react-router-dom";
import {NavItemCompany} from "./NavItemCompany";
import HttpRequest from "../../Http/HttpRequest";
import Notify from "../../Notify/Notify";
import {CurrentTitle} from "./CurrentTitle";

export const MyNav = () => {

  const logout = async () => {
    console.log('do logout');
    await HttpRequest.post(process.env.REACT_APP_AUTH_SERVICE + 'v1/logout',null);
    Notify.success("Deslogado!");
    setTimeout(() => {
      window.location = '/accounts'
    }, 300);
  }

  return (
      <nav className="main-header navbar navbar-expand">
        <ul className="navbar-nav">

          <li className="nav-item">
            <Link className="nav-link" to="" data-widget="pushmenu"
                  onClick={e => e.preventDefault()} role="button"><i
                className="fas fa-bars"/></Link>
          </li>

          <li className="nav-item">
            <Link className="nav-link" to="/">
              <i className="fas fa-home"/></Link>
          </li>
        </ul>

        <ul className="navbar-nav ml-auto">
          {/*<CompanyName/>*/}
          <CurrentTitle/>
        </ul>
        <ul className="navbar-nav ml-auto">

          <NavItemCompany/>

          <li className="nav-item">
            <Link to="/" onClick={logout} className="nav-link" role="button"
                  title="Sair">
              <i className="fas fa-sign-out-alt"/>
            </Link>
          </li>

        </ul>
      </nav>)

}
