import React from "react";
import {TableButton} from "./TableButton";

export const EditButton = ({row}) => {

  return (<TableButton href={'./form/' + row.original.id} className="bg-primary">
        <span className="text-left">
          <i className="fas fa-pencil-alt" aria-hidden="true"></i>
        </span>
        {/*<i className="fas fa-pencil-alt mr-1"></i>*/}
        &nbsp; Editar
      </TableButton>
  )
}
