import React, {useState} from "react";
import MonthSelector, {MONTH_TYPES} from "../../../Common/Component/Select/MonthSelector";
import YearSelector, {YEAR_TYPES} from "../../../Common/Component/Select/YearSelector";
import {Page} from "../../../Common/Component/Page/Page";
import {Button, Card, Col, Row} from "react-bootstrap";
import EmployerSelector from "../../ESocial/Employers/EmployerSelector";
import Notify from "../../../Common/Notify/Notify";
import {getLastDayOfMonths} from "../../../Common/Utils/DateFormat";

export const DownloadSpedFiscalPage = () => {

  const [month, setMonth] = useState(MONTH_TYPES[new Date().getMonth()]);
  const [year, setYear] = useState(YEAR_TYPES[0]);
  const [employer, setEmployer] = useState(null);

  return <Page title="Baixar SPED Fiscal Mensal">
    <Card>
      <Card.Body>
        <Row className="d-flex align-items-center justify-content-center ">
          <Col sm={6}>
            <EmployerSelector isEnableNfe={true} value={employer} setValue={setEmployer}/>
          </Col>
          <Col sm={3}>
            <MonthSelector value={month} setValue={setMonth}/>
          </Col>
          <Col sm={3}>
            <YearSelector value={year} setValue={setYear}/>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
          </Col>
          <Col sm={4}>
            <Button size="sm" variant="primary" style={{width: "100%"}}
                    disabled={!(employer && month)}
                    onClick={() => {
                      Notify.success('Suas notas estão sendo geradas...');
                      const date = getLastDayOfMonths(month.id, year.id);
                      window.open('/api/cflow-sefaz/v1/nfe-sc/employers/' + employer.id + '/speds-by-months?startDate=' + date.startDate + '&endDate=' + date.endDate)
                    }}>
              <Row className="d-flex justify-content-around">
                <Col className="text-left">
                  <i className="fas fa-download"/>
                </Col>
                <Col className="text-center text-nowrap">
                  Gerar SPED`s Fiscais
                </Col>
                <Col>
                </Col>
              </Row>
            </Button>
          </Col>
          <Col>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  </Page>
}
