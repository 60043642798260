import React from "react";
import {TableButton} from "./TableButton";

export const ProfileButton = ({row}) => {
  console.log(row)
  return (<TableButton href={'/cadastros/profiles/form/' + row.original.id} className="bg-green">
        <span className="text-left">
          <i className="fas fa-users" aria-hidden="true"></i>
        </span>
      &nbsp; Permissões
    </TableButton>
  )
}
