import {TableButtonOptions} from "../../ListPage/Components/Table/Buttons/TableButtonGroup";
import {BlockCompanyTableButton} from "../Company/Component/BlockCompanyTableButton";
import {ProfileButton} from "../../ListPage/Components/Table/Buttons/ProfileButton";
import React from "react";

const UserListSchema = () => {

  const endpoint = `${process.env.REACT_APP_AUTH_SERVICE}v1/users/`;
  return {
    getTitle: () => 'Lista de Usuários',

    getEndpoint: () => endpoint,

    getColumns: () => {
      return [
        {
          header: "Nome",
          accessorKey: "name"
        },
        {
          header: "E-mail",
          accessorKey: "email"
        },

        TableButtonOptions({
          deleteEndpoint: endpoint,
          children: ({row, table}) => {
            return <>
              <ProfileButton row={row}/>
              <BlockCompanyTableButton row={row} updateTable={() => {
                table.setGlobalFilter(new Date());
              }}/>
            </>
          },
          showProfileButton: true
        }),
        // OptionColumn({
        //   showDelete: false,
        //   showBlock: true,
        //   endpointToDelete: endpoint,
        //   editTo: ROUTE_PATH.USER_FORM,
        //   entity: 'Usuários',
        //   children: ({row}) => (
        //       <TableButton icon="fas fa-eye" variant="secondary" title="Perfis" to={`${ROUTE_PATH.USER_PERFIL}${row.original.id}`}/>
        //   )
        // })
      ]
    },

  }
}

export default UserListSchema;

