import {Col, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import HttpRequest from "../../../Common/Http/HttpRequest";
import Notify from "../../../Common/Notify/Notify";
import {Page} from "../../../Common/Component/Page/Page";
import SmallBox from "../../../Common/Component/SmallBox/SmallBox";

const DashboardPage = () => {

  const [totalEmployers, setTotalEmployers] = useState('-');
  const [totalEmployersUsingNfe, setTotalEmployersUsigngNfe] = useState('-');

  useEffect(() => {
    Promise.all([HttpRequest.get('cflow-esocial/v1/employers/count'), HttpRequest.get('cflow-esocial/v1/employers/nfe/count')]).then(x => {
      const [employer, employerNfe] = x;
      setTotalEmployers(employer.total);
      setTotalEmployersUsigngNfe(employerNfe.total);
    }).catch(e => Notify.error('Falha ao contar empregadores usando o NFE', e))
  }, []);

  return (<Page>
    <Row>
      <Col sm={6} md={3}>
        <SmallBox
            icon="fa fa-industry"
            count={totalEmployers} title="Empregadores" navigateTo="/cadastros/empregadores"/>
      </Col>

      <Col sm={6} md={3}>
        <SmallBox
            type="secondary"
            icon="fa fa-book-open"
            count={totalEmployersUsingNfe} title="Empregadores Usando NFE"
            navigateTo="/cadastros/empregadores?enableNFE=true"/>
      </Col>
    </Row>
  </Page>);

}

export default DashboardPage;
