export const UserDetailFormSchema = () => {

  return {

    getSaveUrl() {
      return `${process.env.REACT_APP_AUTH_SERVICE}v1/users`;
    },


    getFooter(){
      return {showCancelButton: false}
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },
        "username": {
          "ui:disabled": true
        },

        // "registrationNumber": {
        //   "ui:help": "CPF ou CNPJ da empresa 000.000.000-00 ou 00.000.000/0000-00",
        //   "ui:field": (props) => {
        //     return (<InputMaskedRegistrationNumber id="registrationNumber" title={props.schema.title} mask={props.schema.mask} onChangeText={props.onChange} value={props.formData}/>)
        //   },
        // },
        // "registrationType": {
        //   "ui:options": {
        //     label: false
        //   },
        //   "ui:field": (props) => {
        //     return (<RegistrationTypeSelector disabled={props.disabled} required={props.required}
        //                                       value={props.formData} setValue={props.onChange}/>
        //     );
        //   }
        // }
      }
    },

    getSchema() {
      return {
        type: "object",
        required: ["name"],
        properties: {
          name: {type: "string", title: "Nome Completo"},
          username: {type: "string", disabled: true, format: 'email', title: "E-mail"},
        },
      };
    }

  }

}
