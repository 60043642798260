import {NavDropdown} from "react-bootstrap";
import {useCallback, useState} from "react";
import {Loading} from "../Loading/Loading";
import HttpRequest from "../../Http/HttpRequest";
import Notify from "../../Notify/Notify";

export const NavItemCompany = () => {

  const [data, setData] = useState(null);

  const loadData = useCallback(() => {
    HttpRequest.get(process.env.REACT_APP_AUTH_SERVICE + 'v1/user-company-accesses')
    .then(data => setData(data))
    .catch(e => Notify.error("Falha ao carregar empresas", e))
  }, []);

  const changeCompany = (target) => {
    const {company} = target;
    console.log('trocando de company')
    HttpRequest.post(`${process.env.REACT_APP_AUTH_SERVICE}v1/login/companies/${company.id}`, {})
    .then(() => {
      console.log('esout aquii patrão')
      Notify.success('Trocando de empresa..');
      setTimeout(() => {
        window.location = '/?reloaded';
      }, 1000)
    })
    .catch(e => Notify.error('Falha ao trocar de empresa', e))
  }

  return (<NavDropdown onClick={loadData}
                       title={<i title="Trocar de entidade"
                                 className="fa fa-building"/>}>

    <Loading isLoading={!data}>

      {data?.map(x => <NavDropdown.Item key={x.id} onClick={(e) =>  e.preventDefault() ||changeCompany(x)}>{x.company.name}</NavDropdown.Item>)}

    </Loading>
  </NavDropdown>)
}
