import React from "react";
import {Dropdown, DropdownButton} from "react-bootstrap";

export const PAGINATION_OPTIONS = [
  {id: 5, label: '5 itens por página'},
  {id: 10, label: '10 itens por página'},
  {id: 15, label: '15 itens por página'},
  {id: 30, label: '30 itens por página'},
  {id: 50, label: '50 itens por página'},
  {id: 100, label: '100 itens por página'}
];

const TablePageSelector = ({
  setValue = null,
  pageSize
}) => {

  return <DropdownButton variant="secondary" size="sm"
                         title={pageSize + " Itens por Pagínas"}>

    {PAGINATION_OPTIONS.map(x => {
      return <Dropdown.Item key={x.id} onClick={() => setValue(x.id)} href="" active={x.id === pageSize}>
        {x.label}
      </Dropdown.Item>
    })
    }
  </DropdownButton>
}

export default TablePageSelector;
