import {CompanyListSchema} from "./Company/CompanyListSchema";
import {CADASTRO_MENU} from "../Modules.routes";
import {MenuItem, MenuService} from "../../Common/Component/Menu/MenuService";
import {addListSchema} from "../ListPage/ListSchemaService";
import {addFormSchema} from "../FormPage/FormSchemaService";
import CompanyFormSchema from "./Company/CompanyFormSchema";
import UserListSchema from "./User/UserListSchema";
import UserFormSchema from "./User/UserFormSchema";
import IntegrationUserListSchema from "./UserIntegration/IntegrationUserListSchema";
import IntegrationUserFormSchema from "./UserIntegration/IntegrationUserFormSchema";
import CurrentUserPage from "./User/CurrentUserPage";
import {Roles} from "./Roles";
import UserPerfilFormSchema from "../FormPage/UserPerfilFormSchema";

export const AuthModule = () => {

  const EMPRESAS_LIST = MenuItem({
    name: 'Empresas',
    path: 'empresas',
    icon: 'fa fa-building',
    canCreateRoute: false,
    roles: [Roles.ROLE_SUPER]
  });

  const USUARIO_LIST = MenuItem({
    name: 'Usuários',
    icon: 'fa fa-users',
    path: 'usuarios',
    canCreateRoute: false
  });

  const USUARIO_INTEGRATION_LIST = MenuItem({
    name: 'Usuários de Integrações',
    icon: 'fa fa-users-cog',
    path: 'usuario-integracao',
    canCreateRoute: false
  });

  const CURENT_USER_PAGE = MenuItem({
    name: 'Meus dados',
    path: 'me',
    canCreateRoute: true,
    canShowInMenu: false,
    element: <CurrentUserPage/>
  });

  MenuService.addMenu(CURENT_USER_PAGE);

  addListSchema('usuarios', UserListSchema)
  addFormSchema('usuarios', UserFormSchema)

  addFormSchema('profiles', UserPerfilFormSchema)

  addListSchema('empresas', CompanyListSchema)
  addFormSchema('empresas', CompanyFormSchema)

  addListSchema('usuario-integracao', IntegrationUserListSchema)
  addFormSchema('usuario-integracao', IntegrationUserFormSchema)

  CADASTRO_MENU.children.push(EMPRESAS_LIST);
  CADASTRO_MENU.children.push(USUARIO_LIST);
  CADASTRO_MENU.children.push(USUARIO_INTEGRATION_LIST);

  return [];

}
