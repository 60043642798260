export const ESocialUserPreferenceFormSchema = () => {
  return {

    getTitle() {
      return 'Configurações do ESocial'
    },

    getUrlFindById() {
      return `${process.env.REACT_APP_ESOCIAL_SERVICE}v1/user-configurations`;
    },

    getSaveUrl() {
      return `${process.env.REACT_APP_ESOCIAL_SERVICE}v1/user-configurations`;
    },

    formatObjectToSave(newDate, oldData) {
      const data = oldData.originalData;
      data.configs = Object.keys(newDate).filter(x => x !== 'originalData').map(x => {
        return {type: x, value: newDate[x]};
      });
      return data;
    },

    formatObjectToGet(formData) {
      const data = {};
      data.originalData = formData;
      formData.configs.forEach(x => {
        data[x.type] = x.value;
      })
      return data;
    },

    getUiSchema() {
      return {
        CAN_SEND_DIRECT: {
          "ui:help": "Quandoe está opção estiver ativada, todo evento enviado para o CFLOW é enviado direto para o ESocial"
        }
      }
    },
    getSchema() {
      return {
        type: "object",
        properties: {
          CAN_SEND_DIRECT: {
            type: "boolean", title: "Transmissão automatica de eventos"
          }
        },
      };
    }

  };
}
