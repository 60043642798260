import React from "react";
import {flexRender} from "@tanstack/react-table";

export const TableBody = ({
  table
}) => {

  return (<tbody>
      {table.getRowModel().rows.map(row => {
        return (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => {
                return (
                    <td key={cell.id}>
                      {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                      )}
                    </td>
                )
              })}
            </tr>
        )
      })}
      {/*{page.map((row) => {*/}
      {/*  prepareRow(row)*/}
      {/*  const rowProps = row.getRowProps();*/}
      {/*  return (<React.Fragment key={rowProps.key}>*/}
      {/*        <tr {...row.getRowProps()}>*/}
      {/*          {row.cells.map(cell => {*/}
      {/*            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>*/}
      {/*          })}*/}
      {/*        </tr>*/}
      {/*        {row.isExpanded && renderRowSubComponent({row, rowProps})}*/}
      {/*      </React.Fragment>*/}
      {/*  )*/}
      {/*})}*/}
      {/*<tr>*/}
      {/*  <td colSpan="100" className="pb-0">*/}
      {/*    <Row>*/}
      {/*      /!*<Col sm={12} md={7}>*!/*/}
      {/*      /!*  Apresentando {page.length} de {controlledPageCount * pageSize}{' '} resultados*!/*/}
      {/*      /!*</Col>*!/*/}
      {/*      /!*<Col sm={8} md={2} xs={8}>*!/*/}
      {/*      /!*  <TablePageSelector value={pageSize} setValue={page => setPageSize(page.id)}/>*!/*/}
      {/*      /!*</Col>*!/*/}
      {/*      /!*<Col sm={12} md={3}>*!/*/}
      {/*      /!*  <TablePagination previousPage={previousPage} pageCount={pageCount}*!/*/}
      {/*      /!*                   canNextPage={canNextPage}*!/*/}
      {/*      /!*                   canPreviousPage={canPreviousPage} gotoPage={gotoPage}*!/*/}
      {/*      /!*                   nextPage={nextPage} pageIndex={pageIndex} pageSize={pageSize}*!/*/}
      {/*      /!*  />*!/*/}
      {/*      /!*</Col>*!/*/}
      {/*    </Row>*/}
      {/*  </td>*/}
      {/*</tr>*/}
      </tbody>
  )
}

