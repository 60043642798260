const UserFormSchema = () => {
  return {

    getTitle() {
      return 'Dados de Usuários';
    },

    getUrlFindById(id) {
      return `${process.env.REACT_APP_AUTH_SERVICE}v1/users/${id}`;
    },

    getSaveUrl(id) {
      return `${process.env.REACT_APP_AUTH_SERVICE}v1/users/${id || ''}`;
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
          "ui:placeholder": "Fernando da Silva",
        },
        "email": {
          "ui:help": "E-mail usado para acessar o sistema e receber informações",
          "ui:placeholder": "exemplo@email.com"
        },
      }
    },

    getSchema() {
      return {
        type: "object",
        required: ["name", "email"],
        properties: {
          name: {type: "string", title: "Nome"},
          email: {type: "string", title: "E-mail", "format": "email"},
        },
      };
    }

  }
}

export default UserFormSchema;

