import {NavLink} from "react-router-dom";
import {Nav} from "react-bootstrap";
import {MenuService} from "../Menu/MenuService";

const ROUTE_PARAM = {
  COMPANY_ID: '{companyId}'
}

const formatUrl = (route, companyId) => {
  return route.replace(ROUTE_PARAM.COMPANY_ID, companyId);
}

export const SidebarMenuItem = ({menu, basePath, currentUser}) => {

  const {name, icon, path, children, newTab} = menu;
  const companyId = currentUser.company.id;

  const childrenMenu = MenuService.getOnlyMenus(children, currentUser).filter(x => x.onlyRoute !== true);

  const hasChildren = childrenMenu.length > 0 && <ul className="nav nav-treeview">
    {childrenMenu.sort(x => x.order)
    .map(x => <SidebarMenuItem key={x.path} menu={x} basePath={path} companyId={companyId} currentUser={currentUser} />)}
  </ul>;

  const correctPath = basePath ? (basePath + '/' + path) : path;
  const urlToGo = formatUrl(correctPath, companyId);

  const onClick = (e) => {
    if (newTab) {
      window.location = urlToGo;
      e.preventDefault();
      return;
    }
    if (hasChildren) {
      e.preventDefault();
    }
  };

  return (
      <Nav.Item className="nav-item">
        <NavLink onClick={onClick} to={urlToGo || ''} className="nav-link">
          <i className={`nav-icon fa ${icon}`}/>
          <p>{name}</p>
          {hasChildren && <i className="fas fa-angle-left right"/>}
        </NavLink>
        {hasChildren}
      </Nav.Item>
  )

}
