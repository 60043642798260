import React from "react";
import {DropdownButton} from "react-bootstrap";
import {DeleteButton} from "./DeleteButton";
import {EditButton} from "./EditButton";
import {flexRender} from "@tanstack/react-table";

export const TableButtonGroup = ({row, children, setGlobalFilter, deleteEndpoint, showEditButton, showDeleteButton}) => {

  return (<DropdownButton variant="secondary" size="sm"
                          title={<i className="fa fa-bars" aria-hidden="true"></i>}>

        {showEditButton && <EditButton row={row}/>}
        {showDeleteButton && <DeleteButton row={row} deleteEndpoint={deleteEndpoint} updateTable={() => setGlobalFilter(new Date())}/>}
        {children}
      </DropdownButton>
  )
}

export const TableButtonOptions = ({children, deleteEndpoint, showEditButton = true, showDeleteButton = true}) => {
  return {
    header: ' ',
    accessorKey: '_options',
    enableSorting: false,
    export: false,
    collapse: true,
    maxWidth: '60',
    enableColumnFilter: false,
    cell: ({row, table, cell}) => {
      return (
          <div className="text-center p-1">
            <TableButtonGroup row={row} setGlobalFilter={table.setGlobalFilter} deleteEndpoint={deleteEndpoint} showEditButton={showEditButton} showDeleteButton={showDeleteButton}>
              {children && flexRender(
                  children,
                  cell.getContext()
              )}
            </TableButtonGroup>
          </div>
      )
    }
  }
}
