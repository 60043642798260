import {BaseTextInput} from "../../../../../Common/Component/Input/BaseTextInput";
import {BaseCheckboxInput} from "../../../../../Common/Component/Input/BaseCheckboxInput";
import {BaseCalendarInput} from "../../../../../Common/Component/Input/BaseCalendarInput";

export function DefaultColumnFilter({header}) {

  const column = header.column;
  const value = column.getFilterValue();

  return (
      <BaseTextInput
          label={column.columnDef.header}
          labelRequired={true}
          showFormComponents={false}
          value={value || ''}
          onChange={e => column.setFilterValue(e.target.value)}
          placeholder={`Filtrar resultados...`}
      />
  )
}

export function CheckboxColumnFilter({column}) {

  const value = column.getFilterValue();

  return (<BaseCheckboxInput
      label={column.columnDef.header}
      labelRequired={true}
      showFormComponents={false}
      checked={!!value}
      onChange={e => column.setFilterValue(e.target.checked)}
      placeholder={`Filtrar resultados...`}
  />)
}

export function DateRangeColumnFilter({column}) {

  const value = column.getFilterValue();

  return (<BaseCalendarInput
      label={column.columnDef.header}
      labelRequired={true}
      defaultValue={value}
      selectionMode="range"
      onChange={e => column.setFilterValue(e)}
      placeholder={`Filtrar resultados...`}
  />)
}

export function DateColumnFilter({column}) {

  const value = column.getFilterValue();

  return (<BaseCalendarInput
      label={column.columnDef.header}
      labelRequired={true}
      defaultValue={value}
      // selectionMode="range"
      onChange={e => {
        if (e) {
          e.onlyDate = true;
        }
        column.setFilterValue(e);
      }}
      placeholder={`Filtrar resultados...`}
  />)
}
