import {UserLoad} from "./Common/Context/UserContext";
import './Base.scss';
import {MyRoutes} from "./Common/Component/Routes/Routes";
import './Modules/Modules.routes';
import {ThemeProvider} from "react-bootstrap";
import {LoadModules} from "./Modules";
import {ToastContainer} from "react-toastify";

LoadModules();

function App() {

  console.log('Renderizando app')
  return (<UserLoad>
    <ThemeProvider
        breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
        minBreakpoint="xxs"
    >
      <MyRoutes/>
      <ToastContainer />
    </ThemeProvider>
  </UserLoad>);
}

export default App;
