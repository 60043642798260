import {MyNav} from "./MyNav";
import {Sidebar} from "../Sidebar/Sidebar";
import {Footer} from "./Footer";
import {Outlet} from "react-router-dom";

export const BaseLayout = () => {

  console.log('Renderizando o BaseLayout, feito')

  return (
      <>
        <MyNav/>
        <Sidebar/>
        <div className="content-wrapper">
          <Outlet/>
        </div>
        <Footer/>
      </>
  )
}
