import CertificateUpload from "./component/CertificateUpload";

const CertificateSchemaForm = {
  type: "object", title: "Certificate",

  "properties": {
    "fullPath": {
      type: 'string',
    }, "name": {
      type: 'string',
    },
  }
}

export const CertificateFormSchema = () => {
  return {

    getTitle() {
      return 'Dados do Certificado';
    },

    getUrlFindById(id) {
      return `${process.env.REACT_APP_ESOCIAL_SERVICE}v1/certificates/${id}`;
    },

    getSaveUrl(id) {
      if (id) {
        return `cflow-esocial/v1/certificates/${id}/newFile`
      }
      return `cflow-esocial/v1/certificates`;
    },

    formatObjectToSave(formData) {
      return {
        ...formData, instance: 'PKCS12'
      };
    },

    getUiSchema: () => {
      return {
        "name": {
          "ui:autofocus": true,
        },

        "certificateFile": {
          "ui:options": {
            label: false

          }, "password": {
            "ui:widget": "password"
          }, "ui:field": (props) => {
            return (<CertificateUpload  {...props}
                                        value={props.formData}
                                        setValue={props.onChange}
            />);
          }
        }
      }
    },

    getSchema() {
      return {
        type: "object", required: ["name", "password"], properties: {
          name: {
            type: "string", title: "Nome amigável para o certificado"
          }, expiryIn: {
            type: "string", title: "Data de Validade do Certificado", format: "date"
          }, password: {
            type: "string", title: "Senha do Certificado", minLength: 4, maxLength: 20
          }, certificateFile: CertificateSchemaForm,
        },
      };
    }
  }
}

