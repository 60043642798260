import {MenuItem} from "../../Common/Component/Menu/MenuService";
import {addListSchema} from "../ListPage/ListSchemaService";
import {PacienteListSchema} from "./Paciente/PacienteListSchema";
import {CADASTRO_MENU} from "../Modules.routes";
import PacienteFormSchema from "./Paciente/PacienteFormSchema";
import {addFormSchema} from "../FormPage/FormSchemaService";
import {Roles} from "../Auth/Roles";

export const DentalModule = () => {

  const PACIENTE_LIST = MenuItem({
    name: 'Pacientes', path: 'pacientes', icon: 'fa fa-stamp', canCreateRoute: false,
    roles: [Roles.ROLE_DENTAL]
  });

  // const EMPLOYER_LIST = MenuItem({
  //   name: 'Empregados', path: 'empregadores', icon: 'fa fa-industry', canCreateRoute: false
  // });

  addListSchema('pacientes', PacienteListSchema);
  addFormSchema('pacientes', PacienteFormSchema);

  CADASTRO_MENU.children.push(PACIENTE_LIST);
  // MenuService.addMenu(CONFIGURATION_MENU);

  return [
    // ...ESocialModules(),
    // ...AuthModule(),
    // ...NfeModuleModule()
  ]
}
