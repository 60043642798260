import {Button, Card, Col, Row} from "react-bootstrap";
import {useState} from "react";
import EmployerSelector from "../../ESocial/Employers/EmployerSelector";
import {Page} from "../../../Common/Component/Page/Page";
import {Loading} from "../../../Common/Component/Loading/Loading";
import {ImportedNfeList} from "./Components/ImportedNfeList";
import Notify from "../../../Common/Notify/Notify";
import HttpRequest from "../../../Common/Http/HttpRequest";

export const NfeImportPage = () => {

  const [employer, setEmployer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasImported, setHasImported] = useState(false);
  const nfeImport = () => {
    setIsLoading(true);
    HttpRequest.get('cflow-sefaz/v1/nfe-sc/employers/' + employer.id + '/find-nfescs').then(x => {
      Notify.success('Notas importadas');
      setHasImported(true);
      setIsLoading(false);
    }).catch(e => {
      Notify.error('Falha ao importar nota!', e);
      setIsLoading(false);
    });
  }

  return (<Page>
    <Card>
      <Card.Body>
        <Row className="d-flex align-items-center justify-content-center ">
          <Col sm={8}>
            <EmployerSelector isEnableNfe={true} value={employer} setValue={setEmployer}/>
          </Col>
          <Col sm={4}>
            <Button size="md" variant="primary" style={{width: "100%"}}
                    disabled={!(employer) || isLoading}
                    onClick={nfeImport}
            >
              <Row className="d-flex justify-content-around">
                <Col className="text-left">
                  <i className="fas fa-download"/>
                </Col>
                <Col className="text-center text-nowrap">
                  {employer ? 'Importar Notas' : 'Selecione um empregador'}
                </Col>
                <Col className="text-right">
                  <Loading isLoading={isLoading}/>
                </Col>
              </Row>
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>

    {hasImported && <ImportedNfeList employer={employer}/>}
  </Page>);

}
