// import axios from 'axios';

export default class HttpRequest {

  static _basePath = '/api/';

  static post(url, data) {

    if (data && !data.company) {
      data.company = {};
      data.company.id = 'WEB';
    }

    return fetch(HttpRequest._basePath + url,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          },
        })
    .then(mapResponse)
  }

  static put(url, data) {
    return fetch(HttpRequest._basePath + url,
        {
          method: "PUT",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          },
        })
    .then(mapResponse)
  }

  static get(url) {
    return fetch(HttpRequest._basePath + url)
    .then(mapResponse)
  }

  static delete(url, data) {
    return fetch(HttpRequest._basePath + url,
        {
          method: "DELETE",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            'Accept': 'application/json'
          },
        })
    .then(mapResponse)
  }
}

const mapResponse = (response) => {
  const {ok} = response;
  return new Promise((resolve, reject) => {
    response.text().then(x => {

      if (x === '') {
        resolve({});
        return;
      }

      try {
        const data = JSON.parse(x);
        ok ? resolve(data) : reject(data);
      } catch (e) {
        reject(x);
      }

    }).catch(reject)
  });
}
