import {getCoreRowModel, getSortedRowModel, useReactTable} from '@tanstack/react-table';
import React, {useEffect, useMemo, useState} from "react";
import BTable from "react-bootstrap/Table";
import {TableHead} from "./TableHead";
import {TableLoading} from "./TableLoading";
import {TableBody} from "./TableBody";
import {getTableData} from "./TableData/TableDataService";
import Notify from "../../../../Common/Notify/Notify";
import {TableFooter} from "./TableFooter";
import {TableFilter} from "./TableFilter";
import {useSearchParams} from "react-router-dom";

const DEFAULT_SORT = [{id: 'createdAt', desc: true}];
const DEFAULT_DATA = {content: [], pageCount: 0, isLoading: true};

const mapQueryStringFilter = (searchParams) => {
  return [...searchParams.keys()].map(x => ({id: x, value: searchParams.get(x)}));
}
export const ReactTable = ({tableSchema}) => {

  const [tableData, setTableData] = useState(DEFAULT_DATA);
  const [schema, setSchema] = useState({columns: [], renderRowSubComponent: null});

  const [{pageIndex, pageSize}, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  let [searchParams] = useSearchParams();

  const [globalFilter, setGlobalFilter] = useState('')
  const [columnFilters, setColumnFilters] = useState(mapQueryStringFilter(searchParams))
  const [columnOrder, setColumnOrder] = useState([]);

  const pagination = useMemo(() => ({
        pageIndex,
        pageSize,
      }),
      [pageIndex, pageSize]
  );

  useEffect(() => {
    if (schema.baseEndpoint) {

      const tableSortBy = columnOrder.length === 0 ? schema.defaultSortBy : columnOrder;

      getTableData({
        pageIndex, pageSize,
        sortBy: tableSortBy,
        filters: columnFilters,
        baseEndpoint: schema.baseEndpoint
      }).then(
          x => setTableData(x),
          err => Notify.error("Falha ao carregar dados", err));
    }
  }, [pageIndex, pageSize,
    columnOrder,
    columnFilters,
    schema,
    globalFilter
  ])

  useEffect(() => {
    const baseEndpoint = tableSchema.getEndpoint();
    const columns = tableSchema.getColumns();
    const defaultSortBy = tableSchema.defaultSortBy ? tableSchema.defaultSortBy() : DEFAULT_SORT;
    const renderRowSubComponent = tableSchema.renderRowSubComponent && tableSchema.renderRowSubComponent();
    setTableData(DEFAULT_DATA);
    setSchema({columns, baseEndpoint, renderRowSubComponent, defaultSortBy});
  }, [tableSchema])

  const tableInstance = useReactTable({
    columns: schema.columns,
    data: tableData.content,
    state: {
      pagination,
      columnFilters,
      globalFilter,
      columnOrder,
    },
    getCoreRowModel: getCoreRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    onColumnOrderChange: setColumnOrder,
    getSortedRowModel: getSortedRowModel(), //order doesn't matter anymore!
    manualPagination: true,
    // debugTable: true,
    // debugHeaders: true,
    // debugColumns: true,
  });

  const headerGroups = tableInstance.getHeaderGroups();

  return (
      <>
        <TableFilter table={tableInstance} headerGroups={headerGroups}/>
        <BTable responsive striped bordered hover size="sm">
          <TableHead headerGroups={headerGroups}/>
          <TableLoading isLoading={tableData.isLoading}>
            <TableBody table={tableInstance}/>
            <TableFooter totalElements={tableData.totalElements} table={tableInstance}/>
          </TableLoading>
        </BTable>
      </>
  )
}

