import EmployerUpload from "./Components/EmployerUpload";

const EmployerImportFormSchema = () => {
  return {

    getSaveUrl() {
      return `cflow-esocial/v1/employers/imports`;
    },

    getUiSchema: () => {
      return {
        "employers": {
          "ui:options": {
            label: false
          },
          "ui:field": (props) => {
            return (<EmployerUpload disabled={props.disabled} required={props.required}
                                    value={null} setValue={props.onChange}/>
            );
          }
        },
      };
    },

    getSchema() {
      return {
        title: "Importar Empregadores",
        type: "object",
        required: ["employers"],
        properties: {
          employers: {
            type: "array",
            title: "Arquivo para importação do empregador"
          },
        }
      };
    }

  }
}

export default EmployerImportFormSchema;

