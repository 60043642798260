import {Form} from "react-bootstrap";
import {Calendar} from "primereact/calendar";
import {useState} from "react";

export const BaseCalendarInput = ({
  showTime = false,
  showButtonBar = true,
  selectionMode = 'single',
  label, required, minLength, labelRequired = true, defaultValue = null, disabled, id, placeholder, onChange,
  hourFormat,
  ...otherProps
}) => {

  const _onChange = (value) => {
    setDates(value);
    onChange && onChange(value);
  }

  const [dates, setDates] = useState(defaultValue);

  return (<Form.Group className="mb-3">
    {labelRequired && <Form.Label htmlFor={id}>{label}</Form.Label>}
    <Calendar required={required}
              inputClassName="form-control form-control-sm"
              inputStyle={{width: '100%', padding: 6}}
              dateFormat="dd/mm/yy"
              style={{display: 'block'}}
              showButtonBar={showButtonBar}
              showTime={showTime}
              hourFormat={hourFormat}
              value={dates}
              placeholder={placeholder}
              disabled={disabled}
              onChange={(e) => _onChange(e.value)}
              selectionMode={selectionMode}
              {...otherProps} />
  </Form.Group>)
}
