import {Button} from "react-bootstrap";
import {Loading} from "../Loading/Loading";

export const AppButton = ({
  children,
  isLoading = false,
  ...otherProps
}) => {

  let spinnerTemplate;
  if (isLoading) {
    spinnerTemplate = (<Loading isLoading={isLoading}/>);
  }

  return (<Button
          size="sm"
      {...otherProps}
      disabled={isLoading}>
      {children || 'Salvar'}
      {spinnerTemplate}
    </Button>
  )
}
