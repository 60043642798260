const getFormDataKey = (entityName) => {
  return `${entityName}-form-data`;
}
export const saveLocalDataForm = (entityName, data) => {
  window.localStorage[getFormDataKey(entityName)] = JSON.stringify(data);
}

export const getLocalDataForm = (entityName, defaultValue) => {
  let value = window.localStorage[getFormDataKey(entityName)];
  if (value) {
    return JSON.parse(value);
  }
  return defaultValue;
}
