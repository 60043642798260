import BaseSelector from "./BaseSelector";

const yearBase = new Date().getFullYear();
export const YEAR_TYPES = [];
for (let i = 0; i < 10; i++) {
  const data = (yearBase - i).toString();
  YEAR_TYPES.push({id: data, label: data});
}

const YearSelector = ({
  required = false,
  title = "Ano",
  setValue = null,
  ...props
}) => {

  return <BaseSelector
      title={title}
      required={required}
      label="id"
      setValue={setValue}
      items={YEAR_TYPES}
      showCreateButton={false}
      {...props}
  />
}

export default YearSelector;
