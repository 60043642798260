import {Card, Col, Row} from "react-bootstrap";
import {Page} from "../../Common/Component/Page/Page";
import {useEffect, useState} from "react";
import {CustomForm} from "./Components/CustomForm";
import {Loading} from "../../Common/Component/Loading/Loading";
import {useParams, useSearchParams} from "react-router-dom";
import {mountFormObject} from "./FormSchemaService";

export const FormPage = () => {

  const {entity, id} = useParams();
  let [searchParams] = useSearchParams();

  const [pageSchema, setPageSchema] = useState({
    isLoading: true
  });


  useEffect(() => {
    mountFormObject({id, entity, searchParams}).then(x => {
      setPageSchema(x);
    });

  }, [id, entity, searchParams]);

  if (!pageSchema) {
    return <Loading/>
  }

  if (pageSchema.isLoading) {
    return <Loading/>
  }

  return (<Page>
    <Card>
      <Card.Body>
        <Row>
          <Col>
            <CustomForm pageSchema={pageSchema}/>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  </Page>)
}
